<div
  [ngStyle]="{ right: index * chatWindowWidth + spacingBtnChatWindow + 'px' }"
  class="message-window"
  [ngClass]="{
    maximized: maximized
  }"
>
  <div
    class="header"
    [ngClass]="{
      bggrey: ['LEAD', 'OPPORTUNITY'].includes(userType),
      bggreen: ['REFERRER', 'BUSINESSCONSULTANT'].includes(userType)
    }"
  >
    <div class="d-flex">
      <div class="userImage d-flex justify-content-center align-items-center">
        <img
          *ngIf="!recipientImgUrl"
          class="img-profile rounded-circle border m-1"
          [ngClass]="{ 'opacity-0': recipientImgUrl }"
          src="assets/images/icons/icon_user.svg"
        />
        <img
          *ngIf="recipientImgUrl"
          class="img-profile rounded-circle border m-1"
          [ngClass]="{ 'opacity-0': !recipientImgUrl }"
          [src]="getEncodingURL(recipientImgUrl)"
        />
      </div>
      <div class="nameWrapper" *ngIf="from === 'message-module'">
        <div class="name">
          <a
            routerLink="/{{
              moduleName === 'lead' ? 'leads' : 'opportunities'
            }}/details/{{ entityId }}"
            target="_blank"
          >
            {{ userDetails.firstName }} {{ userDetails.lastName }}</a
          >
        </div>
        <div class="business">{{ userDetails.entityName }}</div>
      </div>
      <div *ngIf="from !== 'message-module'">
        <div
          class="nameWrapper"
          *ngIf="['LEAD', 'OPPORTUNITY'].includes(userType)"
        >
          <div class="name">
            <a
              routerLink="/{{
                moduleName === 'lead' ? 'leads' : 'opportunities'
              }}/details/{{ entityId }}"
              target="_blank"
            >
              {{ userDetails.firstName }} {{ userDetails.lastName }}</a
            >
          </div>
          <div class="business">
            {{ 'shared.common.business' | translate }}
          </div>
        </div>
        <div class="nameWrapper" *ngIf="userType == 'REFERRER'">
          <div class="name">
            {{ userDetails.branchEmpName }} {{ userDetails.firstName }}
            {{ userDetails.lastName }}
          </div>
          <div class="business">{{ 'text.referrer' | translate }}</div>
        </div>
        <div class="nameWrapper" *ngIf="userType == 'BUSINESSCONSULTANT'">
          <div class="name">
            {{ userDetails.ownerName }}
          </div>
          <div class="business">
            {{ 'text.business.consultant' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="buttonWrapper">
      <button
        class="btn btn-icon"
        *ngIf="!minimized && !isSmartDevice"
        (click)="minimizeWindow()"
      >
        <img src="assets/images/icons/icon_whiteMinimize.svg" />
      </button>
      <button
        class="btn btn-icon"
        *ngIf="!maximized && !isSmartDevice"
        (click)="maximizeWindow()"
      >
        <img src="assets/images/icons/icon_whiteMaximize.svg" />
      </button>
      <button class="btn btn-icon" (click)="close()">
        <img src="assets/images/icons/icon_whiteCross.svg" />
      </button>
    </div>
  </div>
  <div
    *ngIf="!minimized"
    class="d-flex flex-column-reverse chatBodyWrapper border-bottom"
    [ngClass]="{
      'maximized-chat': maximized
    }"
  >
    <mat-spinner [hidden]="chatLoaded" diameter="20"></mat-spinner>
    <ng-container *ngIf="textActivityTab && chatLoaded">
      <div
        class="d-flex m-2"
        *ngFor="let tabItem of textActivityTab; let i = index"
        [ngClass]="
          !tabItem.inBound ? 'justify-content-end' : 'justify-content-start'
        "
      >
        <div
          class="d-flex justify-content-center"
          [ngClass]="tabItem.inBound ? 'order-1' : 'order-2'"
        >
          <img
            *ngIf="(!senderImgUrl && !tabItem.inBound) || (!recipientImgUrl &&  tabItem.inBound)"
            class="img-profile rounded-circle border m-1"
            [ngClass]="{ 'opacity-0': !tabItem.showIcon }"
            src="assets/images/icons/icon_user.svg"
          />
          <img
            *ngIf="senderImgUrl && !tabItem.inBound"
            class="img-profile rounded-circle border m-1"
            [ngClass]="{ 'opacity-0': !tabItem.showIcon }"
            [src]="getEncodingURL(senderImgUrl)"
          />
          <img
            *ngIf="recipientImgUrl && tabItem.inBound"
            class="img-profile rounded-circle border m-1"
            [ngClass]="{ 'opacity-0': !tabItem.showIcon }"
            [src]="getEncodingURL(recipientImgUrl)"
          />
        </div>
        <div class="w-75" [ngClass]="tabItem.inBound ? 'order-2' : 'order-1'">
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <mat-spinner
                class="me-1"
                diameter="15"
                *ngIf="!tabItem.inBound && tabItem.status === 'sending'"
              ></mat-spinner>
            </div>

            <img
              class="me-1"
              src="assets/images/icons/icon_tick_delivered.svg"
              *ngIf="!tabItem.inBound && tabItem.status === 'SENT'"
            />
            <div
              class="p-2 fs-14 rounded text-break flex-grow-1"
              [ngClass]="!tabItem.inBound ? 'bggreen' : 'bggrey'"
              [innerHtml]="tabItem.message"
            ></div>
          </div>

          <div
            class="mt-1 fs-12"
            [ngClass]="{
              'text-start ms-4': !tabItem.inBound
            }"
          >
            <span [hidden]="!tabItem.showTime"> {{ tabItem.time }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <clover-no-data
      *ngIf="!textActivityTab.length && chatLoaded"
      message="{{ 'noRecordsAvailable' | translate }}"
    ></clover-no-data>
  </div>
  <div *ngIf="!minimized" class="chatFooter">
    <div *ngIf="chatLoaded" class="d-flex justify-content-between">
      <textarea
        placeholder="{{ 'type.message.here' | translate }}"
        [(ngModel)]="chat"
        name="chat"
        (keyup.enter)="onSubmit()"
        maxlength="1000"
        class="textbox p-2 w-70"
      >
      </textarea>
      <div class="pt-2 pe-2 positionRelative action-container">
        <div class="d-md-flex align-items-center justify-content-end m-0">
          <img
            class="pe-2"
            src="assets/images/icons/icon_files12.svg"
            (click)="insertQuickText()"
          />
          <button
            (click)="onSubmit()"
            class="small-btn edit-btn"
            [disabled]="!chatLoaded"
          >
            {{ 'agenda.common.send' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
