import { prospectsStatusInterface } from './../../model/interfaces/prospect';

export const yourlistViewGridDefaultColumn = [
  {
    dataFieldName: 'name',
    displayName: 'List Name',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.listName',
  },
  {
    dataFieldName: 'description',
    displayName: 'Description',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.description',
  },
  {
    dataFieldName: 'totalProspects',
    displayName: 'Total Prospects',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.totalProspects',
  },
  {
    dataFieldName: 'creatorUserName',
    displayName: 'Created By',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.createdBy',
  },
  {
    dataFieldName: 'createDate',
    displayName: 'Created On',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'yourlistTableColumnName.createdOn',
  },
  {
    dataFieldName: 'updatedDate',
    displayName: 'Last Status Change',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'yourlistTableColumnName.lastStatusChange',
  },
  {
    dataFieldName: 'avgProcessingVolume',
    displayName: 'Avg Processing Volume',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.avgProcessingVolume',
  },
  {
    dataFieldName: 'adminUsers',
    displayName: 'Admins',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.admins',
  },
  {
    dataFieldName: 'contributorUsers',
    displayName: 'Contributors',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.contributors',
  },
];

export const yourlistGridViewColumns = [
  {
    dataFieldName: 'name',
    displayName: 'List Name',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.listName',
  },
  {
    dataFieldName: 'description',
    displayName: 'Description',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.description',
  },
  {
    dataFieldName: 'totalProspects',
    displayName: 'Total Prospects',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.totalProspects',
  },
  {
    dataFieldName: 'creatorUserName',
    displayName: 'Created By',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.createdBy',
  },
  {
    dataFieldName: 'createDate',
    displayName: 'Created On',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'yourlistTableColumnName.createdOn',
  },
  {
    dataFieldName: 'updatedDate',
    displayName: 'Last Status Change',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'yourlistTableColumnName.lastStatusChange',
  },
  {
    dataFieldName: 'avgProcessingVolume',
    displayName: 'Avg Processing Volume',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.avgProcessingVolume',
  },
  {
    dataFieldName: 'adminUsers',
    displayName: 'Admins',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.admins',
  },
  {
    dataFieldName: 'contributorUsers',
    displayName: 'Contributors',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.contributors',
  },
  {
    dataFieldName: 'totalLeads',
    displayName: 'Total Lead',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.totalLead',
  },
  {
    dataFieldName: 'totalOpenProspects',
    displayName: 'Total Open Prospects',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'yourlistTableColumnName.totalOpenProspects',
  },
  {
    dataFieldName: 'totalAccounts',
    displayName: 'Total Accounts',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospects.summary.totalAccounts',
  },
  {
    dataFieldName: 'totalOpportunities',
    displayName: 'Total Opportunities',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'totalOpportunities',
  },
];

export const prospectViewGridDefaultColumn = [
  {
    dataFieldName: 'customerName',
    displayName: 'Business Name',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.businessName',
  },
  {
    dataFieldName: 'stage',
    displayName: 'Stage',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.stage',
  },
  {
    dataFieldName: 'status',
    displayName: 'Status',
    isSortable: true,
    hasFilter: true,
    fieldType: 'multi',
    multiLanguageKey: 'prospectTableColumnName.status',
  },
  {
    dataFieldName: 'branchName',
    displayName: 'Branch Name',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.branchName',
  },
  {
    dataFieldName: 'createdDate',
    displayName: 'Date Added',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'grid.filter.dateCreated',
  },
  {
    dataFieldName: 'processingVolume',
    displayName: 'Processing Volume',
    isSortable: true,
    hasFilter: true,
    fieldType: 'range',
    multiLanguageKey: 'prospectTableColumnName.processingVolume',
  },
  {
    dataFieldName: 'projectedRevenue',
    displayName: 'Projected Revenue',
    isSortable: true,
    hasFilter: true,
    fieldType: 'range',
    multiLanguageKey: 'prospectTableColumnName.projectedRevenue',
  },
  {
    dataFieldName: 'processor',
    displayName: 'Processor',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.processor',
  },
  {
    dataFieldName: 'numListsIn',
    displayName: 'In # of Lists',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.inOfLists',
  },
];

export const unqualifiedReason = [
  {
    value: 'Customer Unreachable',
    key: 'Customer Unreachable',
    multiLanguageKey: 'unqualifiedReason1',
  },
  {
    value: 'Refused to Engage',
    key: 'Refused to Engage',
    multiLanguageKey: 'unqualifiedReason2',
  },
  {
    value: 'Duplicate',
    key: 'Duplicate',
    multiLanguageKey: 'unqualifiedReason3',
  },
  {
    value: 'Already Processing with Partnership',
    key: 'Already Processing with Partnership',
    multiLanguageKey: 'unqualifiedReason4',
  },
  {
    value: 'Do Not Call',
    key: 'Do Not Call',
    multiLanguageKey: 'unqualifiedReason5',
  },
  {
    value: 'Suspect',
    key: 'Suspect',
    multiLanguageKey: 'unqualifiedReason6',
  },
  {
    value: 'Wants a Non-Merchant Product',
    key: 'Wants a Non-Merchant Product',
    multiLanguageKey: 'unqualifiedReason7',
  },
  {
    value: 'Restricted Business Type',
    key: 'Restricted Business Type',
    multiLanguageKey: 'unqualifiedReason8',
  },
  {
    value: 'Sales Rep Rejects',
    key: 'Sales Rep Rejects',
    multiLanguageKey: 'unqualifiedReason9',
  },
  {
    value: 'Closed for Business',
    key: 'Closed for Business',
    multiLanguageKey: 'unqualifiedReason10',
  },
];

export const prospectGridViewColumns = [
  {
    dataFieldName: 'customerName',
    displayName: 'Business Name',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.businessName',
  },
  {
    dataFieldName: 'stage',
    displayName: 'Stage',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.stage',
  },
  {
    dataFieldName: 'status',
    displayName: 'Status',
    isSortable: true,
    hasFilter: true,
    fieldType: 'multi',
    multiLanguageKey: 'prospectTableColumnName.status',
  },
  {
    dataFieldName: 'branchName',
    displayName: 'Branch Name',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.branchName',
  },
  {
    dataFieldName: 'createdDate',
    displayName: 'Date Added',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'grid.filter.dateCreated',
  },
  {
    dataFieldName: 'processingVolume',
    displayName: 'Processing Volume',
    isSortable: true,
    hasFilter: true,
    fieldType: 'range',
    multiLanguageKey: 'prospectTableColumnName.processingVolume',
  },
  {
    dataFieldName: 'projectedRevenue',
    displayName: 'Projected Revenue',
    isSortable: true,
    hasFilter: true,
    fieldType: 'range',
    multiLanguageKey: 'prospectTableColumnName.projectedRevenue',
  },
  {
    dataFieldName: 'processor',
    displayName: 'Processor',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.processor',
  },
  {
    dataFieldName: 'numListsIn',
    displayName: 'In # of Lists',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.inOfLists',
  },
  {
    dataFieldName: 'accountNumberMasked',
    displayName: 'Bank DDA',
    isSortable: false,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.bankDDA',
  },
  {
    dataFieldName: 'customerTaxIdMasked',
    displayName: 'Tax ID',
    isSortable: false,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.taxId',
  },
  {
    dataFieldName: 'bankOfficer',
    displayName: 'Bank Officer',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.bankOfficer',
  },
  {
    dataFieldName: 'branchAddress',
    displayName: 'Branch Address',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.branchAddress',
  },
  {
    dataFieldName: 'branchCity',
    displayName: 'Branch City',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.branchCity',
  },
  {
    dataFieldName: 'branchId',
    displayName: 'Branch Id',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.branchId',
  },
  {
    dataFieldName: 'branchState',
    displayName: 'Branch State',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.branchState',
  },
  {
    dataFieldName: 'businessAddress',
    displayName: 'Business Address',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.businessAddress',
  },
  {
    dataFieldName: 'businessEmail',
    displayName: 'Business Email Address',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.businessEmailAddress',
  },
  {
    dataFieldName: 'businessPhone',
    displayName: 'Business Phone Number',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.businessPhoneNumber',
  },
  {
    dataFieldName: 'contactEmail',
    displayName: 'Contact Email Address',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.contactEmailAddress',
  },
  {
    dataFieldName: 'contactName',
    displayName: 'Contact Name',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.contactName',
  },
  {
    dataFieldName: 'contactPhone',
    displayName: 'Contact Phone Number',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.contactPhoneNumber',
  },
  {
    dataFieldName: 'contactRelationship',
    displayName: 'Contact Relationship',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.contactRelationship',
  },
  {
    dataFieldName: 'daysSinceClosedLostDate',
    displayName: 'Days Since Closed Lost',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.daysSinceClosedLost',
  },
  {
    dataFieldName: 'daysSinceLastContactDate',
    displayName: 'Days Since Last Contact',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.daysSinceLastContact',
  },
  {
    dataFieldName: 'daysSinceUnqualifiedDate',
    displayName: 'Days Since Unqualified',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.daysSinceUnqualified',
  },
  {
    dataFieldName: 'leadOwner',
    displayName: 'Lead Owner',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.leadOwner',
  },
  {
    dataFieldName: 'opportunityOwner',
    displayName: 'Opportunity Owner',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.opportunityOwner',
  },
  {
    dataFieldName: 'processingVolumeGroup',
    displayName: 'Processing Volume Group',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.processingVolumeGroup',
  },
  {
    dataFieldName: 'branchAssignedBC',
    displayName: 'Branch Assigned BC',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospectTableColumnName.branchAssignedBC',
  },
  {
    dataFieldName: 'firstTransationDate',
    displayName: 'First Transaction Date',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'prospectTableColumnName.firstTransactionDate',
  },
  {
    dataFieldName: 'lastTransationDate',
    displayName: 'Last Transaction Date',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'prospectTableColumnName.lastTransactionDate',
  },
  {
    dataFieldName: 'dateRefreshed',
    displayName: 'Last Refresh Date',
    isSortable: true,
    hasFilter: true,
    fieldType: 'date',
    multiLanguageKey: 'prospectTableColumnName.lastRefreshDate',
  },
  {
    dataFieldName: 'bankRegion',
    displayName: 'Bank Region',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospect.prospectOverview.lead.summary.bankRegion',
  },
  {
    dataFieldName: 'bankDivision',
    displayName: 'Bank Division',
    isSortable: true,
    hasFilter: true,
    fieldType: 'text',
    multiLanguageKey: 'prospect.prospectOverview.lead.summary.bankDivision',
  },
];

export const simpleTableGrid = [
  {
    dataFieldName: 'name',
    displayName: 'Name',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'prospectSimpleTable.name',
  },
  {
    dataFieldName: 'title',
    displayName: 'Title',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'prospectSimpleTable.title',
  },
  {
    dataFieldName: 'lastModified',
    displayName: 'Last Modified',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'prospectSimpleTable.lastModified',
  },
  {
    dataFieldName: 'action',
    displayName: 'Action',
    isSortable: false,
    hasFilter: false,
    fieldType: 'text',
    multiLanguageKey: 'prospectSimpleTable.action',
  },
];
export const multiFilterOptions = {
  status: [
    { code: 'Attempt to Contact', desc: 'Attempt to Contact' },
    { code: 'Closed Lost', desc: 'Closed Lost' },
    { code: 'Closed Won', desc: 'Closed Won' },
    { code: 'Contacted', desc: 'Contacted' },
    { code: 'Convert to Lead', desc: 'Converted to Lead' },
    { code: 'Do Not Contact', desc: 'Do Not Contact' },
    { code: 'Open', desc: 'Open' },
    { code: 'Prospecting', desc: 'Prospecting' },
    { code: 'Unqualified', desc: 'Unqualified' },
  ],
  branchName: [
    { code: 'THE PEOPLES SAVINGS BANK', desc: 'THE PEOPLES SAVINGS BANK' },
    { code: 'Brooklyn', desc: 'Brooklyn' },
    { code: 'Long Island City', desc: 'Long Island City' },
  ],
};
export const prospectsStatus: prospectsStatusInterface[] = [
  { id: 11, status: 'Open', value: 'Open' },
  { id: 12, status: 'Attempt to Contact', value: 'Attempt to contact' },
  { id: 14, status: 'Do Not Contact', value: 'Do Not Contact' },
  { id: 15, status: 'Unqualified', value: 'Unqualified' },
  { id: 16, status: 'Convert to Lead', value: 'Convert to Lead' },
];
const commonStaticContent = {
  somethingApiError: 'something.wrong',
  cancel: 'common.button.text.cancel',
  removeButton: 'shared.common.text.remove',
  deleteButton: 'shared.common.text.delete',
  confirm: 'shared.common.text.confirm',
};

export const ProspectStaticContent = {
  ...commonStaticContent,
  prospectConvertToLeadSuccessMessage:
    'prospects.convertToLeadSuccessfulMessage',
  phoneEmailError: 'lead.create.emailPhoneHint',
  confirmYourListDelete: 'prospects.yourList.delete.confirm.title',
  selectedListMessage: 'prospects.yourList.delete.confirm.message',
  confirmDeleteAdmin: 'prospects.yourList.details.admin.delete.confirm.title',
  deleteAdminMessage: 'prospects.yourList.details.admin.delete.confirm.message',
  titleAdmin: 'prospects.yourList.details.adminTable.add.button',
  titleContibutors: 'prospects.yourList.details.contributorTable.add.button',
  selectAdminMsg:
    'prospects.yourList.details.adminTable.add.dialog.selectAdmin',
  selectContributorMsg:
    'prospects.yourList.details.contributorsTable.add.dialog.selectAdmin',
  notesMsg: 'prospects.yourList.details.simpleTable.add.dialog.notes',
  optionalText: 'prospects.yourList.details.simpleTable.add.dialog.optional',
  addButton: 'prospects.yourList.details.simpleTable.add.dialog.addButton',
  statusUpdateSuccess: 'prospects.status.success.message',
  titleCreateNewList: 'prospects.prospects.createList.title',
  labelListName: 'prospects.prospects.createList.listName',
  labelDescription: 'shared.description',
  buttonCreateNewList: 'prospects.prospects.createList.button',
  addWarningText: 'prospects.prospects.createList.warningText',
  existingListDialogTitle: 'prospects.prospects.existingList.dialog.title',
  existingListDialogInfo: 'prospects.prospects.existingList.dialog.info',
  labelReason: 'prospects.prospectList.status.dialog.label',
  titleUnqualifyStatus: 'prospects.prospectList.status.unqualify.dialog.title',
  buttonUnqualifyStatus:
    'prospects.prospectList.status.unqualify.dialog.button',
  titleDoNotContactStatus:
    'prospects.prospectList.status.doNotContact.dialog.title',
  buttonDoNotContactButton:
    'prospects.prospectList.status.doNotContact.dialog.button',
  titleBulkStatusDialog: 'prospects.bulkStatus.dialog.title',
  warningMsgBulkStatusDialog: 'prospects.bulkStatus.dialog.warningMsg',
  labelBulkStatusDialog: 'prospects.bulkStatus.dialog.label',
  labelBulkreasonLabel: 'prospects.bulkStatus.dialog.reasonlabel',
  actionButtonBulkStatusDialog: 'prospects.bulkStatus.dialog.button',
  displayYourListDialogTitle: 'prospects.yourlist.dialog.title',
  displayYourListDialogSubTitle: 'prospects.yourlist.dialog.subTitle',
  confirmProspectListDelete: 'prospects.delete.confirm.title',
  selectedProspectMessage: 'prospects.delete.confirm.message',
  displayAddProspectDialogTitle: 'prospect.tab.prospectData',
  successTitle: 'upload.referral.sheet.dialog.success',
  successMessage: 'prospect.status.update.successfully',
  createListMessage: 'prospect.create.newlist.dialog.message',
  addPropsectToListMessage: 'prospect.add.prospect.to.yourlist.dialog.message',
  confirmDescriptionMessage: 'youlist.summary.description.confirm.message',
  confirmDescriptionTitle: 'youlist.summary.description.confirm.title',
  contributorErrorMsg: 'youlist.details.add.contributor.error.msg',
  adminErrorMsg: 'youlist.details.add.admin.error.msg',
  gotoLeadPage: 'converted.prospect.to.leadPage',
  convertToLeadSuccess: 'prospect.convert.success.Message',
  leadOwnerMessage: 'converted.prospect.to.lead.info.text',
  opportunityOwnerMessage: 'converted.prospect.to.opportunity.info.text',
};
