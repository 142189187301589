<div class="row">
  <div class="col-md-12">
    <h1 class="fs-26 mb-4" *ngIf="searchResultTotalCount">
      {{ 'lead.view.grid.filter.showing' | translate }}
      {{ searchResultTotalCount }} {{ 'search.result.for' | translate }} "{{
        searchTxt
      }}"
    </h1>
    <h1 class="fs-26 mb-4" *ngIf="!searchResultTotalCount">
      {{ 'no.result.found.for' | translate }} "{{ searchTxt }}"
    </h1>
  </div>
</div>
<mat-card appearance="outlined">
  <div class="row mb-2">
    <div
      *ngIf="hasMoaPriviledge || !displayReferralSearch"
      [ngClass]="{
        'col-12 mb-4': !isRefineSearchAvailable,
        'col-9': isRefineSearchAvailable
      }"
    >
      <ng-container *ngFor="let tab of activeTabs">
        <span
          *ngIf="tab.show"
          class="vertical-line"
          (click)="onTabChanged(tab.value)"
        >
          <span
            class="tab"
            [class.circle-desc-active]="currentTab == tab.value"
          >
            <strong class="circles"
              ><span class="count-circle d-flex justify-content-center">{{
                tab.count
              }}</span></strong
            >
            <span class="mx-2 fs-14 pe-3">{{ tab.tab }}</span>
          </span>
        </span>
      </ng-container>
    </div>
    <div
      *ngIf="
        isRefineSearchAvailable && (hasMoaPriviledge || !displayReferralSearch)
      "
      class="col-3 d-flex justify-content-end"
    >
      <button class="outline-primary" (click)="OpenRefineSearchPopup()">
        {{ 'refineSearch' | translate }}
      </button>
    </div>
  </div>
  <div>
    <div
      [hidden]="!searchResultSelectedTabCount"
      class="table-container fixedHeader mt-2"
      [ngClass]="{ singleRow: dataSource.data.length <= 2 }"
    >
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        [ngStyle]="{
          'min-width': displayColumnsDefined.length * 200 + 'px'
        }"
      >
        <ng-container
          *ngFor="
            let disCol of displayColumnsDefined;
            let colIndex = index;
            let last = last
          "
          matColumnDef="{{ disCol.dataFieldName }}"
        >
          <th mat-header-cell *matHeaderCellDef [ngStyle]="{ width: '200px' }">
            <span
              [mat-sort-header]="disCol.isSortable ? disCol.dataFieldName : ''"
              [disabled]="!disCol.isSortable"
              disableClear
            >
              {{ disCol.multiLanguageKey || '' | translate }}</span
            >
            <span
              *ngIf="disCol.hasFilter"
              [title]="
                (filterMap.has(disCol.dataFieldName) || disCol.showFilter
                  ? 'apply.filter'
                  : 'show.filters'
                ) | translate
              "
              (click)="toggleFilter(disCol, $event)"
              class="filterIcon"
              [ngClass]="{
                'filter-applied':
                  filterMap.has(disCol.dataFieldName) || disCol.showFilter
              }"
            ></span>
            <clover-mat-column-filter
              [ngClass]="{ 'right-align': last }"
              *ngIf="
                disCol.showFilter &&
                disCol.fieldType != 'date' &&
                disCol.fieldType != 'multi' &&
                disCol.dataFieldName != 'leadRating'
              "
              [field]="disCol.dataFieldName"
              [previousVal]="filterMap.get(disCol.dataFieldName)"
              (applyEvent)="onApply($event)"
              (clearEvent)="clearFilters(disCol.dataFieldName)"
              cloverOutsideClick
              (appOutsideClick)="outsideClick($event)"
            >
            </clover-mat-column-filter>
            <clover-mat-column-filter
              *ngIf="disCol.showFilter && disCol.fieldType === 'date'"
              [field]="disCol.dataFieldName"
              [previousVal]="
                filterMap.get(disCol.dataFieldName)
                  ? filterMap.get(disCol.dataFieldName).split(':')[0] +
                    ',' +
                    filterMap.get(disCol.dataFieldName).split(':')[1]
                  : ','
              "
              [datefilter]="disCol.dateFilter"
              (applyEvent)="onApply($event, disCol.dataFieldName)"
              (clearEvent)="clearFilters(disCol.dataFieldName)"
              cloverOutsideClick
              (hideEvent)="hideFilters('')"
            >
            </clover-mat-column-filter>
            <clover-mat-column-filter
              *ngIf="disCol.showFilter && disCol.fieldType === 'multi'"
              [previousVal]="filterMap.get(disCol.dataFieldName)"
              [dropDown]="true"
              [multiple]="true"
              [filteredOptions]="filterLeadStatus"
              [filteredOptions]="
                multiFilterOptions[currentTab + '_' + disCol.dataFieldName]
              "
              [field]="disCol.dataFieldName"
              (applyEvent)="onApply($event, disCol.dataFieldName)"
              (clearEvent)="clearFilters(disCol.dataFieldName)"
              (hideEvent)="hideFilters('')"
            ></clover-mat-column-filter>
            <clover-mat-column-filter
              *ngIf="disCol.showFilter && disCol.dataFieldName === 'leadRating'"
              [previousVal]="filterMap.get(disCol.dataFieldName)"
              [dropDown]="true"
              [multiple]="true"
              [filteredOptions]="leadRatingFilter"
              [field]="'leadRating'"
              (applyEvent)="onApply($event, disCol.dataFieldName)"
              (clearEvent)="clearFilters(disCol.dataFieldName)"
              (hideEvent)="hideFilters('')"
            ></clover-mat-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="
                disCol.dataFieldName != 'ACCOUNTCOMPANY' &&
                disCol.dataFieldName != 'company' &&
                disCol.dataFieldName != 'accountName' &&
                disCol.dataFieldName != 'businessName' &&
                disCol.dataFieldName != 'legalName' &&
                disCol.dataFieldName != 'leadRating' &&
                disCol.dataFieldName != 'tags' &&
                disCol.dataFieldName != 'listName' &&
                disCol.dataFieldName != 'admins' &&
                disCol.dataFieldName != 'contributors' &&
                disCol.dataFieldName != 'stage' &&
                disCol.dataFieldName != 'status' &&
                disCol.fieldType != 'date' &&
                disCol.displayName != 'Action'
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  replyThread;
                  context: {
                    $implicit: element[disCol.dataFieldName + '_value']
                  }
                "
              ></ng-container>
            </span>
            <span
              *ngIf="
                disCol.displayName != 'Action' &&
                disCol.dataFieldName == 'ACCOUNTCOMPANY' &&
                !['1', '2'].includes(userRoleId)
              "
              class="link"
              (click)="navigateToDetails(element)"
            >
              <ng-container
                *ngTemplateOutlet="
                  replyThread;
                  context: {
                    $implicit: element[disCol.dataFieldName + '_value']
                  }
                "
              ></ng-container>
            </span>
            <span
              *ngIf="
                disCol.displayName != 'Action' &&
                disCol.dataFieldName == 'ACCOUNTCOMPANY' &&
                ['1', '2'].includes(userRoleId)
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  replyThread;
                  context: {
                    $implicit: element[disCol.dataFieldName + '_value']
                  }
                "
              ></ng-container>
            </span>
            <span *ngIf="disCol.fieldType == 'date'">
              {{
                element[disCol.dataFieldName] &&
                element[disCol.dataFieldName][0]
                  ? convertDateToEst(
                      element[disCol.dataFieldName + '_value'][0]
                    )
                  : ''
              }}</span
            >
            <a
              class="link"
              *ngIf="
                disCol.dataFieldName == 'company' ||
                disCol.dataFieldName == 'legalName'
              "
              routerLink="/{{
                currentTab == 'lead' ? 'leads' : 'opportunities'
              }}/details/{{ element.id }}"
            >
              <ng-container
                *ngTemplateOutlet="
                  replyThread;
                  context: {
                    $implicit: element[disCol.dataFieldName + '_value']
                  }
                "
              ></ng-container>
            </a>
            <span *ngIf="disCol.dataFieldName == 'accountName'">
              <a
                class="link"
                *ngIf="element.isViewable"
                routerLink="/myaccounts/details/{{ element.id }}"
              >
                <ng-container
                  *ngTemplateOutlet="
                    replyThread;
                    context: {
                      $implicit: element[disCol.dataFieldName + '_value']
                    }
                  "
                ></ng-container>
              </a>
              <span *ngIf="!element.isViewable">
                <ng-container
                  *ngTemplateOutlet="
                    replyThread;
                    context: {
                      $implicit: element[disCol.dataFieldName + '_value']
                    }
                  "
                ></ng-container>
              </span>
            </span>
            <a
              class="link"
              *ngIf="disCol.dataFieldName == 'listName'"
              routerLink="/moa/yourlist/details/{{ element.id }}"
            >
              <ng-container
                *ngTemplateOutlet="
                  replyThread;
                  context: {
                    $implicit: element[disCol.dataFieldName + '_value']
                  }
                "
              ></ng-container>
            </a>
            <a
              class="link"
              *ngIf="disCol.dataFieldName == 'businessName'"
              routerLink="/moa/prospect/details/{{ element.id }}"
            >
              <ng-container
                *ngTemplateOutlet="
                  replyThread;
                  context: {
                    $implicit: element[disCol.dataFieldName + '_value']
                  }
                "
              ></ng-container>
            </a>
            <span *ngIf="disCol.dataFieldName == 'leadRating'">
              <span *ngFor="let rating of [1, 2, 3]">
                <img
                  class="w-20"
                  *ngIf="rating <= element.leadRating"
                  src="assets/images/icons/icon_fire_filled.svg"
                />
                <img
                  class="w-20"
                  *ngIf="rating > element.leadRating"
                  src="assets/images/icons/icon_fire_outline.svg"
                />
              </span>
            </span>
            <span
              *ngIf="
                disCol.dataFieldName == 'admins' ||
                disCol.dataFieldName == 'contributors'
              "
            >
              {{ element[disCol.dataFieldName + '_value'][0] | slice : 0 : 1 }}
              <span
                class="link"
                *ngIf="element[disCol.dataFieldName + '_value'][0].length > 1"
                >+<a
                  [title]="
                    getTooltipData(
                      element[disCol.dataFieldName + '_value'][0].slice(
                        1,
                        element[disCol.dataFieldName + '_value'][0].length
                      )
                    )
                  "
                >
                  {{ element[disCol.dataFieldName + '_value'][0].length - 1 }}
                </a>
              </span>
            </span>
            <span
              *ngIf="disCol.dataFieldName == 'stage'"
              class="stage-prospect"
              [ngClass]="{
                'stage-prospect-enable': element.stage == 'PROSPECT',
                'stage-opportunity': element.stage == 'OPPORTUNITY',
                'stage-lead': element.stage == 'LEAD',
                'stage-acc': element.stage == 'ACC',
                'stage-prospect-disable':
                  element.stage == 'PROSPECT' &&
                  element.statusName == 'Unqualified'
              }"
            >
              {{ element.stage == 'ACC' ? 'ACCOUNT' : element.stage }}
            </span>
            <span *ngIf="disCol.dataFieldName == 'status'">
              {{ element.status }}
            </span>
            <button
              *ngIf="disCol.displayName == 'Action'"
              class="btn btn-icon"
              (click)="displayLeadDetails(element)"
            >
              <img src="assets/images/icons/icon_zoom.svg" alt="" />
            </button>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <ng-template #replyThread let-childReplies>
        <span *ngFor="let value of childReplies; let i = index">
          <span *ngIf="childReplies.length === 1">{{
            value ? value : '--'
          }}</span>
          <span *ngIf="childReplies.length > 1 && i % 2 == 0">{{ value }}</span>
          <span *ngIf="i % 2 == 1" [innerHtml]="value"></span>
        </span>
      </ng-template>
    </div>
    <div
      *ngIf="
        !yourListData.isLoaded ||
        !prospectData.isLoaded ||
        !leadsData.isLoaded ||
        !opportunitiesData.isLoaded ||
        !referralsData.isLoaded ||
        !accountsData.isLoaded
      "
      class="d-flex justify-content-center align-items-center positionRelative p-5"
    >
      <mat-progress-spinner
        [diameter]="40"
        color="primary"
        mode="indeterminate"
      >
      </mat-progress-spinner>
    </div>
    <div
      *ngIf="
        yourListData.isLoaded &&
        prospectData.isLoaded &&
        leadsData.isLoaded &&
        opportunitiesData.isLoaded &&
        referralsData.isLoaded &&
        accountsData.isLoaded &&
        searchResultSelectedTabCount == 0
      "
      class="d-flex flex-column justify-content-center align-items-center p-5"
    >
      <p><img src="assets/images/icons/icon_info_alert_1.svg" /></p>
      <p class="primary-color">{{ 'search.norecords' | translate }}</p>
    </div>
    <div
      *ngIf="
        yourListData.isLoaded &&
        prospectData.isLoaded &&
        leadsData.isLoaded &&
        opportunitiesData.isLoaded &&
        referralsData.isLoaded &&
        accountsData.isLoaded &&
        searchResultSelectedTabCount &&
        dataSource.data.length == 0
      "
      class="no-result-position"
    >
      <p><img src="assets/images/icons/icon_info_alert_1.svg" /></p>
      <p class="primary-color">{{ 'lead.editColumns.noLead' | translate }}</p>
    </div>
    <mat-paginator
      #paginator
      cloverStylePaginator
      [hidden]="
        !yourListData.isLoaded ||
        !prospectData.isLoaded ||
        !leadsData.isLoaded ||
        !opportunitiesData.isLoaded ||
        !referralsData.isLoaded ||
        !accountsData.isLoaded ||
        dataSource.data.length == 0
      "
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="onPaginate($event)"
    >
    </mat-paginator>
  </div>
</mat-card>
