import { BreadcrumbsResponse } from './../../model/interfaces/opportunities';
import {
  BcEmailResponse,
  BranchEmpResponse,
  BranchListResponse,
  BulkAssignBcPayload,
  CampaignDetailResponse,
  CreateNewLeadResponse,
  GetCampaignList,
  Industries,
  LeadPostResponse,
  LeadReq,
  LeadSource,
  LeadSourceResponse,
  LeadStatus,
  LeadTypeResponse,
  UnQualifiedList,
  UnQualifiedListResponse,
} from './../../model/interfaces/leads';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ReminderResponse,
  IndustryResponse,
} from '@app/model/interfaces/leads';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  CampaignsTaskResponse,
  LinearCampaignResponse,
} from '@app/model/interfaces/campaign';
import { BranchSetup } from '@app/model/interfaces/settings';
import { CampaignsPopupResponse } from '@app/model/interfaces/agenda';
import { CreateNewLeadPayload } from './../../model/interfaces/leads';
import { CheckLeadStatusResponse } from '@app/model/interfaces/notification';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private leadSources!: LeadSource[];
  private industryCollection!: Industries[];
  private unQualifiedReasonList!: UnQualifiedList[];
  private leadStatusList!: LeadStatus[];
  refreshProspectiveTask = new BehaviorSubject('');
  constructor(private http: HttpClient) {}

  getLists(
    payload: LeadReq,
    moduleName = 'leads'
  ): Observable<ReminderResponse> {
    const url = `${environment.salesRegimeServices}/v1/${
      moduleName == 'account' ? 'account/opportunity' : moduleName
    }`;

    return this.http.post<ReminderResponse>(url, payload).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }
  getIndustryCollection = () => this.industryCollection;
  setIndustryCollection = (industries: Industries[]) => {
    this.industryCollection = industries;
  };
  getUnQualifiedReasonList = () => this.unQualifiedReasonList;
  setUnQualifiedReasonList = (unQualifiedReasonList: UnQualifiedList[]) => {
    this.unQualifiedReasonList = unQualifiedReasonList;
  };

  getLeadStatusList = () => this.leadStatusList;
  setLeadStatusList = (leadStatusList: LeadStatus[]) => {
    this.leadStatusList = leadStatusList;
  };

  getLeadSource = () => this.leadSources;
  setLeadSource = (leadSources: LeadSource[]) => {
    this.leadSources = leadSources;
  };

  getCounts(
    payload: LeadReq,
    moduleName = 'leads'
  ): Observable<ReminderResponse> {
    return this.http
      .post<ReminderResponse>(
        `${environment.salesRegimeServices}/v1/${moduleName}/count`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  getSummary(
    payload: LeadReq,
    moduleName = 'leads'
  ): Observable<ReminderResponse> {
    const url = `${environment.salesRegimeServices}/v1/${
      moduleName == 'account' ? 'account/opportunity' : moduleName
    }/summary`;

    return this.http.post<ReminderResponse>(url, payload).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }
  getMarketPlaceOpportunityStatus(
    id: number,
    moduleName: string
  ): Observable<BreadcrumbsResponse> {
    return this.http
      .get<BreadcrumbsResponse>(
        `${environment.salesRegimeServices}/v1/${moduleName}/${id}/view`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  getLeadDetails(
    id: number,
    moduleName: string,
  ): Observable<any> {
    const url = `${environment.salesRegimeServices}/v1/${moduleName}/${id}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getBusinessConsultantDetails(
    entityType: string,
    entityId: number
  ): Observable<ReminderResponse> {
    return this.http
      .get<ReminderResponse>(
        `${environment.salesRegimeServices}/v1/businessconsultant/${entityType}/${entityId}`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  sendMail = (
    id: string,
    moduleName: string,
    payLoad: any
  ): Observable<any> => {
    const url = `${environment.salesRegimeServices}/v1/${moduleName}/${id}/mails`;
    return this.http.post(url, payLoad).pipe(
      catchError(this.handleError),
      tap((response: any) => {})
    );
  };

  updateLeadDetails(
    id: number,
    payload: LeadReq,
    moduleName: string
  ): Observable<LeadPostResponse> {
    return this.http
      .post<LeadPostResponse>(
        `${environment.salesRegimeServices}/v1/${moduleName}/${id}`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getCampaignDataByLeadId = (
    leadId: number
  ): Observable<CampaignsPopupResponse> => {
    const url = `${environment.salesRegimeServices}/v1/lead/${leadId}/campaign`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: CampaignsPopupResponse) => {})
    );
  };
  getAgendaTasks = (leadId: number): Observable<CampaignsTaskResponse> => {
    const url = `${environment.salesRegimeServices}/v1/agenda/${leadId}/task`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: CampaignsTaskResponse) => {})
    );
  };
  getAllAgendaTasks = (leadId: number): Observable<LinearCampaignResponse> => {
    const url = `${environment.salesRegimeServices}/v1/lead/${leadId}/campaign/allTasks`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((response: LinearCampaignResponse) => {})
    );
  };

  getIndustryType(): Observable<IndustryResponse> {
    return this.http
      .get<IndustryResponse>(
        `${environment.salesRegimeServices}/v1/industryTypes`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getLeadTypes(): Observable<LeadTypeResponse> {
    return this.http
      .get<LeadTypeResponse>(`${environment.salesRegimeServices}/v1/leadTypes`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getLeadSources(): Observable<LeadSourceResponse> {
    return this.http
      .get<LeadSourceResponse>(
        `${environment.salesRegimeServices}/v1/leadSources`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getAutoSuggestForBC(keyword: string): Observable<any> {
    return this.http
      .get<any>(`${environment.salesRegimeServices}/v1/bc/${keyword}`)
      .pipe(
        tap((response: LeadTypeResponse) => {}),
        catchError(this.handleError)
      );
  }

  getAutoSuggestForBCByPartner(keyword: string, bankId: number): Observable<any> {
    return this.http
      .get<any>(`${environment.salesRegimeServices}/v1/bank/${bankId}/bc/${keyword}`)
      .pipe(
        tap((response: LeadTypeResponse) => {}),
        catchError(this.handleError)
      );
  }

  bulkAssignBc = (payload: BulkAssignBcPayload) => {
    return this.http
    .post<any>(`${environment.salesRegimeServices}/v1/lead/bulkAssign`, payload)
    .pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  }

  getBranchesForSelectedEmp(payload: { userId: string }): Observable<any> {
    return this.http
      .post<any>(`${environment.salesRegimeServices}/v1/referrerBranches`, payload)
      .pipe(
        catchError(this.handleError),
        tap((response: BranchListResponse) => {})
      );
  }

  getAutoSuggestForBranchEmp(keyword: string, bankId: number): Observable<any> {
    return this.http
      .get<any>(`${environment.salesRegimeServices}/v1/bank/${bankId}/branchEmployee/${keyword}`)
      .pipe(
        tap((response: BranchEmpResponse) => {}),
        catchError(this.handleError)
      );
  }

  getCampaignList = (
    leadId: number,
    payload: GetCampaignList,
    moduleName: string
  ): Observable<any> => {
    return this.http
      .get<any>(
        `${environment.salesRegimeServices}/v1/${moduleName}/${leadId}/candidatecampaigns/?sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}&pageSize=${payload.pageSize}&pageNum=${payload.pageIndex}${payload.filterParams}`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  };

  saveNewLead(
    payload: CreateNewLeadPayload
  ): Observable<CreateNewLeadResponse> {
    return this.http
      .post<CreateNewLeadResponse>(
        `${environment.salesRegimeServices}/v1/lead`,
        payload
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  getUnqualifiedReasons(module = 'lead'): Observable<UnQualifiedListResponse> {
    return this.http
      .get<UnQualifiedListResponse>(
        `${environment.salesRegimeServices}/v1/${module}/unqualifiedReason`
      )
      .pipe(
        catchError(this.handleError),
        tap((res: UnQualifiedListResponse) => {
          this.setUnQualifiedReasonList(res.data);
        })
      );
  }
  converOpportunity(leadId: number): Observable<any> {
    return this.http
      .post<any>(
        `${environment.salesRegimeServices}/v1/leads/converted/${leadId}`,
        {}
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  loadLeadStatus(): Observable<any> {
    return this.http
      .get<any>(`${environment.salesRegimeServices}/v1/lead/leadStatus`, {})
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  getBranchFullList(bankId: number): Observable<BranchSetup> {
    return this.http
      .get<BranchSetup>(
        `${environment.referralServices}/v1/bank/${bankId}/setup/`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  checkLatestStatus = (id: number) => {
    return this.http
      .get(`${environment.salesRegimeServices}/v1/entity/latest/lead/${id}`)
      .pipe(
        catchError(this.handleError),
        tap((response: CheckLeadStatusResponse) => {})
      );
  };

  getHierarchyAgentList(bankId: number): Observable<BranchSetup> {
    return this.http
      .get<BranchSetup>(
        `${environment.salesRegimeServices}/v1/${bankId}/hierarchyAgent`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
