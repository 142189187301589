import { ChangeDetectorRef } from '@angular/core';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MatPaginator,
  MatPaginatorIntl,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { pageSizeOptions } from '@app/appConstants';
import { LeadService } from '@app/features/leads/lead.service';
import {
  accountSearchResultsDefined,
  leadsearchResultsDefined,
  moaListSearchResultsDefined,
  opportunitySearchResultsDefined,
  propectsSearchResultsDefined,
  refineSearchTagClicked,
  searchResultsDefined,
} from '@app/model/const/search';
import { ColumnFilterValue } from '@app/model/interfaces/common';
import { HeaderService } from '@app/shared/services/header.service';
import { ToasterService } from '@app/shared/services/toaster.service';
import { UserInfoService } from '@app/shared/services/user-info.service';
import {
  convertDateToEst,
  formatPhoneNumber,
} from '@app/shared/utils/functions';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import {
  Expression,
  FileLeadStatus,
  FilterRefineData,
  GetSearchResults,
  LoadLeadStatus,
  RefineSearchTagClickedItem,
  SearchReq,
  SearchResultColumns,
  SearchResultResponse,
  SearchTabData,
  searchResultTab,
} from '@app/model/interfaces/menu';
import { BankInfoService } from '@app/shared/services/bank-info.service';
import { LeadDetailsDialogComponent } from '@app/features/referral/referral-activity/lead-details-dialog/lead-details-dialog.component';
import { ReferralService } from '@app/features/referral/referral.service';
import { SharedService } from '@app/shared/shared.service';
import { LanguageService } from '@app/shared/services/language.service';
import { RefineSearchComponent } from '../refine-search/refine-search.component';
import { prospectsStatusInterface } from '@app/model/interfaces/prospect';
import {
  ProspectStaticContent,
  prospectsStatus,
  unqualifiedReason,
  multiFilterOptions,
} from '@app/features/prospects/prospectConstant';

@Component({
  selector: 'clover-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  providers: [{ provide: MatPaginatorIntl }],
})
export class SearchResultComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchTxt!: string;
  payload!: SearchReq;
  isSdUser = this.sharedService.isSdUser();
  hasMoaPriviledge = Boolean(
    this.sharedService.getProfileDetails().moaPrivilege
  );
  staticLocaleContent: {
    [key: string]: string;
  } = this.language.getLocaleString(ProspectStaticContent);
  isMoaAdmin = this.sharedService.getProfileDetails().moaPrivilege === 30;
  selectedFilterColumnData!: number;
  userRoleId: string = this.sharedService.getProfileDetails().role;
  pageSizeOptions = pageSizeOptions;
  pageSize = pageSizeOptions[0];
  totalRecords = 0;
  isGridDataLoaded = false;
  fetchRefineSearchData!: any;
  editColumnsList: Array<any> = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  userInfo = this.userInfoService.getUserInfo();
  displayReferralSearch = this.userInfo.displayReferralSearch;
  searchResultStaticContent = {
    somethingWrong: 'something.wrong',
    leads: 'shared.leads',
    opportunity: 'opportunities',
    moaLists: 'moa.lists',
    prospects: 'leftNav.prospects',
    referrals: 'referralTableColumnName.referrals',
    accounts: 'shared.accounts',
  };
  staticContent = this.language.getLocaleString(this.searchResultStaticContent);

  displayColumnsDefined: SearchResultColumns[] = [];
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();

  yourListData: SearchTabData = {
    isLoaded: this.hasMoaPriviledge ? false : true,
    totalRecords: 0,
    data: [],
  };
  prospectData: SearchTabData = {
    isLoaded: this.isMoaAdmin ? false : true,
    totalRecords: 0,
    data: [],
  };
  leadsData: SearchTabData = {
    isLoaded: !this.displayReferralSearch ? false : true,
    totalRecords: 0,
    data: [],
  };
  opportunitiesData: SearchTabData = {
    isLoaded: !this.displayReferralSearch ? false : true,
    totalRecords: 0,
    data: [],
  };
  referralsData: SearchTabData = {
    isLoaded: this.displayReferralSearch ? false : true,
    totalRecords: 0,
    data: [],
  };
  accountsData: SearchTabData = {
    isLoaded: !this.displayReferralSearch ? false : true,
    totalRecords: 0,
    data: [],
  };
  searchResultTotalCount = 0;
  searchResultSelectedTabCount = 0;
  errorCode: string | undefined;
  totalpages: number | undefined;
  recordsperpage: number | undefined;
  lastContactedDays!: string;
  lastContactedDay!: string;
  activeTabs: searchResultTab[] = [
    {
      tab: this.staticContent.moaLists,
      value: 'moalist',
      show: this.hasMoaPriviledge,
      count: 0,
    },
    {
      tab: this.staticContent.prospects,
      value: 'prospect',
      show: this.hasMoaPriviledge && this.isMoaAdmin,
      count: 0,
    },
    {
      tab: this.staticContent.leads,
      value: 'lead',
      show: !this.displayReferralSearch,
      count: 0,
    },
    {
      tab: this.staticContent.opportunity,
      value: 'opportunity',
      show: !this.displayReferralSearch,
      count: 0,
    },
    {
      tab: this.staticContent.referrals,
      value: 'referral',
      show: this.displayReferralSearch,
      count: 0,
    },
    {
      tab: this.staticContent.accounts,
      value: 'account',
      show: !this.displayReferralSearch && !this.isSdUser,
      count: 0,
    },
  ];
  leadRatingFilter = [
    {
      code: '0',
      desc: '0',
      isRating: true,
    },
    {
      code: '1',
      desc: '1',
      isRating: true,
    },
    {
      code: '2',
      desc: '2',
      isRating: true,
    },
    {
      code: '3',
      desc: '3',
      isRating: true,
    },
  ];

  multiFilterOptions: any = {
    prospect_status: [...multiFilterOptions.status],
    lead_leadStatus: [],
  };
  filterMap = new Map();
  tableWidth = '0px';
  currentTab!: string;
  globalSearchSubscription!: Subscription;
  subscriptionGetSearchResults!: Subscription;
  filterLeadStatus: FileLeadStatus[] = [];
  gridViewFilter: any = [];
  prospectsStatus: prospectsStatusInterface[] = [...prospectsStatus];
  UnQualifiedList: { value: string; key: string; multiLanguageKey: string }[] =
    [...unqualifiedReason];
  isRefineSearchAvailable = false;
  isProspectDetailsLoaded = true;
  constructor(
    public route: ActivatedRoute,
    private router: Router,
    public headerService: HeaderService,
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private language: LanguageService,
    private cdRef: ChangeDetectorRef,
    private referralService: ReferralService,
    private leadService: LeadService,
    private userInfoService: UserInfoService,
    private sharedService: SharedService,
    private bankingService: BankInfoService
  ) {}

  ngOnInit(): void {
    this.isRefineSearchAvailable =
      this.userInfoService.getAvailableModuleFeatures().isRefineSearchAvailable;
    const searchText = localStorage.getItem(`globalSearchText`);
    if (searchText) {
      this.headerService.globalSearchTextChanged.next(searchText);
    }
    this.globalSearchSubscription =
      this.headerService.globalSearchTextChanged.subscribe({
        next: (res: string) => {
          res = res.trim();
          if (this.searchTxt === res) {
            return;
          }
          this.filterMap = new Map();
          this.searchTxt = res;
          if (this.paginator) {
            this.paginator.firstPage();
          }
          this.resetData();
          this.fetchRefineSearchData = null;
          this.fetchDataUsingRefineSearchData();
        },
      });

    if (this.displayReferralSearch) {
      this.displayColumnsDefined = searchResultsDefined;
      this.displayedColumns = searchResultsDefined.map(
        (col) => col.dataFieldName
      );
    } else {
      this.displayColumnsDefined = leadsearchResultsDefined;
      this.displayedColumns = leadsearchResultsDefined.map(
        (col) => col.dataFieldName
      );
      this.dataSource.paginator = this.paginator;
      this.loadLeadStatus();
    }
  }
  resetData = () => {
    this.pageSizeOptions = pageSizeOptions;
    this.pageSize = pageSizeOptions[0];
    this.totalRecords = 0;
    this.yourListData = {
      isLoaded: this.hasMoaPriviledge ? false : true,
      totalRecords: 0,
      data: [],
    };
    this.prospectData = {
      isLoaded: this.isMoaAdmin ? false : true,
      totalRecords: 0,
      data: [],
    };
    this.leadsData = {
      isLoaded: !this.displayReferralSearch ? false : true,
      totalRecords: 0,
      data: [],
    };
    this.opportunitiesData = {
      isLoaded: !this.displayReferralSearch ? false : true,
      totalRecords: 0,
      data: [],
    };
    this.referralsData = {
      isLoaded: this.displayReferralSearch ? false : true,
      totalRecords: 0,
      data: [],
    };
    this.accountsData = {
      isLoaded: !this.displayReferralSearch && !this.isSdUser ? false : true,
      totalRecords: 0,
      data: [],
    };
    this.activeTabs.forEach((item: searchResultTab) => (item.count = 0));
    this.searchResultTotalCount = 0;
    this.searchResultSelectedTabCount = 0;
  };
  loadLeadStatus = () => {
    this.leadService.loadLeadStatus().subscribe(
      (res) => {
        if (res.statusCode == 200) {
          res.data.forEach((val: LoadLeadStatus) => {
            const obj = { code: val.value, desc: val.name };
            this.multiFilterOptions.lead_leadStatus.push(obj);
          });
        } else {
          this.toasterService.error(res.message);
        }
      },
      (err) => {
        this.toasterService.error(err);
      }
    );
  };

  ngAfterViewInit(): void {
    const sortState: Sort = { active: 'dateCreated', direction: 'desc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (
      data: any,
      sortHeaderId: string
    ): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
    this.sort.sortChange.subscribe(() => {
      if (this.sort.direction === '') {
        this.sort.active = '';
      }
      if (this.paginator) {
        this.paginator.firstPage();
      }
      if (this.currentTab == 'referral') {
        this.payload = { ...this.headerService.getSearchPayload() };
        this.payload.sortBy = this.sort.active || 'email';
        this.payload.sortOrder = this.sort.direction || 'ASC';
        this.invokeReferralSearch(false);
      } else {
        this.applySelectedFilters(this.gridViewFilter);
      }
    });
    this.cdRef.detectChanges();
  }
  onTabChanged = (tab: string) => {
    const sortState: Sort = { active: 'dateCreated', direction: 'desc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.filterMap = new Map();
    this.gridViewFilter = [];
    this.currentTab = tab;
    this.yourListData.isLoaded = true;
    this.prospectData.isLoaded = true;
    this.leadsData.isLoaded = true;
    this.opportunitiesData.isLoaded = true;
    this.accountsData.isLoaded = true;
    this.referralsData.isLoaded = true;
    if (tab === 'moalist') {
      this.yourListData.isLoaded = false;
      this.displayColumnsDefined = moaListSearchResultsDefined;
      this.displayedColumns = moaListSearchResultsDefined.map(
        (col) => col.dataFieldName
      );
      this.totalRecords = this.yourListData.totalRecords;
      this.searchResultSelectedTabCount = this.yourListData.totalRecords;
      this.paginator.firstPage();
      this.applySelectedFilters([], false);
    } else if (tab === 'prospect') {
      this.prospectData.isLoaded = false;
      this.displayColumnsDefined = propectsSearchResultsDefined;
      this.displayedColumns = propectsSearchResultsDefined.map(
        (col) => col.dataFieldName
      );
      this.totalRecords = this.prospectData.totalRecords;
      this.searchResultSelectedTabCount = this.prospectData.totalRecords;
      this.paginator.firstPage();
      this.applySelectedFilters([], false);
    } else if (tab === 'lead') {
      this.leadsData.isLoaded = false;
      this.displayColumnsDefined = leadsearchResultsDefined;
      this.displayedColumns = leadsearchResultsDefined.map(
        (col) => col.dataFieldName
      );
      this.totalRecords = this.leadsData.totalRecords;
      this.searchResultSelectedTabCount = this.leadsData.totalRecords;
      this.paginator.firstPage();
      this.applySelectedFilters([], false);
    } else if (tab === 'opportunity') {
      this.opportunitiesData.isLoaded = false;
      this.displayColumnsDefined = opportunitySearchResultsDefined;
      this.displayedColumns = opportunitySearchResultsDefined.map(
        (col) => col.dataFieldName
      );
      this.totalRecords = this.opportunitiesData.totalRecords;
      this.searchResultSelectedTabCount = this.opportunitiesData.totalRecords;
      this.paginator.firstPage();
      this.applySelectedFilters([], false);
    } else if (tab === 'account') {
      this.accountsData.isLoaded = false;
      this.displayColumnsDefined = accountSearchResultsDefined;
      this.displayedColumns = accountSearchResultsDefined.map(
        (col) => col.dataFieldName
      );
      this.totalRecords = this.accountsData.totalRecords;
      this.searchResultSelectedTabCount = this.accountsData.totalRecords;
      this.paginator.firstPage();
      this.applySelectedFilters([], false);
    } else {
      this.displayColumnsDefined = searchResultsDefined;
      this.displayedColumns = searchResultsDefined.map(
        (col) => col.dataFieldName
      );
      this.totalRecords = this.referralsData.totalRecords;
      this.searchResultSelectedTabCount = this.referralsData.totalRecords;
      this.paginator.firstPage();
      this.payload = { ...this.headerService.getSearchPayload() };
      this.invokeReferralSearch(false);
    }
  };

  convertDateToEst = (date: string) =>
    moment(convertDateToEst(date, true)).format('MMM DD, YYYY');

  getPreDefinedTags = () => {
    const predefinedTags = JSON.parse(
      JSON.stringify(
        this.isSdUser
          ? refineSearchTagClicked.filter(
              (tag) => tag.dataFieldName != 'merchantMid'
            )
          : refineSearchTagClicked
      )
    );
    predefinedTags[0].dataFieldName =
      this.currentTab === 'account'
        ? 'accountName'
        : this.currentTab === 'lead'
        ? 'company'
        : 'legalName';
    predefinedTags.forEach((element: RefineSearchTagClickedItem) => {
      element.value = this.searchTxt;
      element.selected = true;
    });
    return predefinedTags;
  };
  OpenRefineSearchPopup = () => {
    const dialogRef = this.dialog.open(RefineSearchComponent, {
      width: '600px',
      height: '100%',
      position: { right: '0' },
      disableClose: true,
      data: {
        currentTab: this.currentTab,
        searchTxt: this.searchTxt,
        displayReferralSearch: this.displayReferralSearch,
        hasMoaPriviledge: this.hasMoaPriviledge,
        isMoaAdmin: this.isMoaAdmin,
        isSdUser: this.isSdUser,
        dateFilter: this.fetchRefineSearchData?.dateFilter || [],
        predefinedTags: this.fetchRefineSearchData
          ? this.fetchRefineSearchData.predefinedTags
          : this.getPreDefinedTags(),
        selectedFilterColumnData: this.fetchRefineSearchData
          ? this.selectedFilterColumnData
          : 2,
        payload: this.fetchRefineSearchData
          ? this.fetchRefineSearchData.payload
          : this.generatePayloadForLeadType(),
      },
    });
    dialogRef.afterClosed().subscribe((result: FilterRefineData) => {
      if (result?.success) {
        this.fetchRefineSearchData = { ...result.data };
        this.selectedFilterColumnData = result.data.selectedFilterColumnData;
        this.searchResultTotalCount = 0;
        this.searchResultSelectedTabCount = 0;
        this.filterMap = new Map();
        this.applySelectedFilters([], true);
      }
    });
  };
  invokeReferralSearch = (updateValue: boolean): void => {
    this.referralsData.isLoaded = false;
    this.dataSource.data = [];
    if (this.searchTxt) {
      this.headerService
        .getSearchReferralResults(
          this.bankingService.getBankId(),
          this.searchTxt,
          this.payload
        )
        .subscribe(
          (data: SearchResultResponse) => this.reloadData(data, updateValue),
          (error) => {
            this.referralsData.isLoaded = true;
            this.setTab();
          }
        );
    }
  };
  reloadData(data: SearchResultResponse, updateValue: boolean): void {
    this.referralsData.isLoaded = true;
    if (data.errorCode === '0') {
      this.totalpages = data.totalPages;
      this.totalRecords = data.totalRecords;
      this.recordsperpage = data.recordsPerPage;
      this.errorCode = data.errorCode;
      this.isGridDataLoaded = true;
      if (updateValue) {
        this.referralsData.data = data.result;
        this.referralsData.totalRecords = data.totalRecords;
        this.activeTabs[4].count = data.totalRecords;
        this.searchResultTotalCount += data.totalRecords;
        this.setTab();
      } else {
        this.updateGridData(data.result, data.totalRecords);
      }
    } else {
      this.dataSource.data = [];
      this.totalpages = data.totalPages;
      this.totalRecords = data.totalRecords;
      this.recordsperpage = data.recordsPerPage;
      this.errorCode = data.errorCode;
      this.setTab();
    }
  }

  displayLeadDetails(leadDetails: any): void {
    this.displayedColumns.forEach((columnName: string) => {
      if (leadDetails[columnName]) {
        leadDetails[columnName] = leadDetails[columnName]
          .split('<strong>')
          .join('');
        leadDetails[columnName] = leadDetails[columnName]
          .split('</strong>')
          .join('');
      }
    });
    const dialogRef = this.dialog.open(LeadDetailsDialogComponent, {
      width: '1000px',
      data: { leadDetails },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  invokeSearch = (
    payload: any,
    updateValue = false,
    moduleName = '',
    refineSearch = false
  ) => {
    const regExp = /[a-zA-Z]/g;
    this.dataSource.data = [];
    if (this.searchTxt && moduleName) {
      const isSearchNumeric = !regExp.test(this.searchTxt);
      payload.searchValue = isSearchNumeric
        ? this.searchTxt.replace(/\D/g, '')
        : this.searchTxt;
      if (this.subscriptionGetSearchResults && !updateValue) {
        this.subscriptionGetSearchResults.unsubscribe();
      }

      if (moduleName === 'moalist') {
        this.yourListData.isLoaded = false;
      }
      if (moduleName === 'prospect') {
        this.prospectData.isLoaded = false;
      }
      if (moduleName === 'lead') {
        this.leadsData.isLoaded = false;
      }
      if (moduleName === 'opportunity') {
        this.opportunitiesData.isLoaded = false;
      }
      if (moduleName === 'account') {
        this.accountsData.isLoaded = false;
      }
      const version = 'v3';
      const bankId = this.bankingService.getBankId();
      this.subscriptionGetSearchResults = this.headerService
        .getSearchResults(moduleName, payload, version, bankId, refineSearch)
        .subscribe(
          (res: GetSearchResults) => {
            if (
              res.statusCode == '0' ||
              res.statusCode == 200 ||
              res.statusCode == 400
            ) {
              if (moduleName === 'moalist') {
                this.yourListData.isLoaded = true;
                if (!res.data) {
                  res.data = {
                    totalPages: 0,
                    totalRecords: 0,
                    currentPage: 0,
                    recordsPerPage: 10,
                    prospectLists: [],
                  };
                }
                if (updateValue) {
                  this.yourListData.data = res.data.prospectLists;
                  this.yourListData.totalRecords = res.data.totalRecords;
                  this.activeTabs[0].count = res.data.totalRecords;
                  this.searchResultTotalCount += res.data.totalRecords;
                  this.setTab();
                } else {
                  this.updateGridData(
                    res.data.prospectLists,
                    res.data.totalRecords
                  );
                }
              }
              if (moduleName === 'prospect') {
                this.prospectData.isLoaded = true;
                if (!res.data) {
                  res.data = {
                    totalPages: 0,
                    totalRecords: 0,
                    currentPage: 0,
                    recordsPerPage: 10,
                    prospectSources: [],
                  };
                }
                if (updateValue) {
                  this.prospectData.data = res.data.prospectSources;
                  this.prospectData.totalRecords = res.data.totalRecords;
                  this.activeTabs[1].count = res.data.totalRecords;
                  this.searchResultTotalCount += res.data.totalRecords;
                  this.setTab();
                } else {
                  this.updateGridData(
                    res.data.prospectSources,
                    res.data.totalRecords
                  );
                }
              }
              if (moduleName === 'lead') {
                this.leadsData.isLoaded = true;
                if (updateValue) {
                  this.leadsData.data = res.data.leadSearchResponseDTOList;
                  this.leadsData.totalRecords = res.data.totalRecords;
                  this.activeTabs[2].count = res.data.totalRecords;
                  this.searchResultTotalCount += res.data.totalRecords;
                  this.setTab();
                } else {
                  this.updateGridData(
                    res.data.leadSearchResponseDTOList,
                    res.data.totalRecords
                  );
                }
              }
              if (moduleName === 'opportunity') {
                this.opportunitiesData.isLoaded = true;
                if (updateValue) {
                  this.opportunitiesData.data =
                    res.data.opportunitySearchResponseDTOList;
                  this.opportunitiesData.totalRecords = res.data.totalRecords;
                  this.activeTabs[3].count = res.data.totalRecords;
                  this.searchResultTotalCount += res.data.totalRecords;
                  this.setTab();
                } else {
                  this.updateGridData(
                    res.data.opportunitySearchResponseDTOList,
                    res.data.totalRecords
                  );
                }
              }
              if (moduleName === 'account') {
                this.accountsData.isLoaded = true;
                if (updateValue) {
                  this.accountsData.data =
                    res.data.accountSearchResponseDTOList;
                  this.accountsData.totalRecords = res.data.totalRecords;
                  this.activeTabs[5].count = res.data.totalRecords;
                  this.searchResultTotalCount += res.data.totalRecords;
                  this.setTab();
                } else {
                  this.updateGridData(
                    res.data.accountSearchResponseDTOList,
                    res.data.totalRecords
                  );
                }
              }
            } else {
              if (moduleName === 'moalist') {
                this.yourListData.isLoaded = true;
              }
              if (moduleName === 'prospect') {
                this.prospectData.isLoaded = true;
              }
              if (moduleName === 'lead') {
                this.leadsData.isLoaded = true;
              }
              if (moduleName === 'opportunity') {
                this.opportunitiesData.isLoaded = true;
              }
              if (moduleName === 'account') {
                this.accountsData.isLoaded = true;
              }
              this.setTab();
            }
          },
          (error) => {
            if (moduleName === 'moalist') {
              this.yourListData.isLoaded = true;
            }
            if (moduleName === 'prospect') {
              this.prospectData.isLoaded = true;
            }
            if (moduleName === 'lead') {
              this.leadsData.isLoaded = true;
            }
            if (moduleName === 'opportunity') {
              this.opportunitiesData.isLoaded = true;
            }
            if (moduleName === 'account') {
              this.accountsData.isLoaded = true;
            }
            this.setTab();
          }
        );
    }
  };

  setTab = () => {
    if (
      this.yourListData.isLoaded &&
      this.prospectData.isLoaded &&
      this.leadsData.isLoaded &&
      this.opportunitiesData.isLoaded &&
      this.accountsData.isLoaded &&
      this.referralsData.isLoaded
    ) {
      let isUpdated = false;
      if (this.currentTab == 'moalist' && this.yourListData.data.length) {
        this.updateGridData(
          this.yourListData.data,
          this.yourListData.totalRecords
        );
        isUpdated = true;
      } else if (
        this.currentTab == 'prospect' &&
        this.prospectData.data.length
      ) {
        this.updateGridData(
          this.prospectData.data,
          this.prospectData.totalRecords
        );
        isUpdated = true;
      } else if (this.currentTab == 'lead' && this.leadsData.data.length) {
        this.updateGridData(this.leadsData.data, this.leadsData.totalRecords);
        isUpdated = true;
      } else if (
        this.currentTab == 'opportunity' &&
        this.opportunitiesData.data.length
      ) {
        this.updateGridData(
          this.opportunitiesData.data,
          this.opportunitiesData.totalRecords
        );
        isUpdated = true;
      } else if (
        this.currentTab == 'account' &&
        this.accountsData.data.length
      ) {
        this.updateGridData(
          this.accountsData.data,
          this.accountsData.totalRecords
        );
        isUpdated = true;
      } else if (
        this.currentTab == 'referral' &&
        this.referralsData.data.length
      ) {
        this.updateGridData(
          this.referralsData.data,
          this.referralsData.totalRecords
        );
        isUpdated = true;
      } else {
      }

      if (!isUpdated) {
        if (
          this.yourListData.data.length ||
          (this.hasMoaPriviledge &&
            !this.prospectData.data.length &&
            !this.leadsData.data.length &&
            !this.opportunitiesData.data.length &&
            !this.accountsData.data.length &&
            !this.referralsData.data.length)
        ) {
          this.currentTab = 'moalist';
          this.displayColumnsDefined = moaListSearchResultsDefined;
          this.displayedColumns = moaListSearchResultsDefined.map(
            (col) => col.dataFieldName
          );
          this.updateGridData(
            this.yourListData.data,
            this.yourListData.totalRecords
          );
        } else if (
          this.prospectData.data.length ||
          (this.isMoaAdmin &&
            !this.leadsData.data.length &&
            !this.opportunitiesData.data.length &&
            !this.referralsData.data.length)
        ) {
          this.currentTab = 'prospect';
          this.displayColumnsDefined = propectsSearchResultsDefined;
          this.displayedColumns = propectsSearchResultsDefined.map(
            (col) => col.dataFieldName
          );
          this.updateGridData(
            this.prospectData.data,
            this.prospectData.totalRecords
          );
        } else if (
          this.leadsData.data.length ||
          (!this.displayReferralSearch &&
            !this.opportunitiesData.data.length &&
            !this.accountsData.data.length)
        ) {
          this.currentTab = 'lead';
          this.displayColumnsDefined = leadsearchResultsDefined;
          this.displayedColumns = leadsearchResultsDefined.map(
            (col) => col.dataFieldName
          );
          this.updateGridData(this.leadsData.data, this.leadsData.totalRecords);
        } else if (
          this.opportunitiesData.data.length ||
          (!this.displayReferralSearch && !this.accountsData.data.length)
        ) {
          this.currentTab = 'opportunity';
          this.displayColumnsDefined = opportunitySearchResultsDefined;
          this.displayedColumns = opportunitySearchResultsDefined.map(
            (col) => col.dataFieldName
          );
          this.updateGridData(
            this.opportunitiesData.data,
            this.opportunitiesData.totalRecords
          );
        } else if (this.accountsData.data.length) {
          this.currentTab = 'account';
          this.displayColumnsDefined = accountSearchResultsDefined;
          this.displayedColumns = accountSearchResultsDefined.map(
            (col) => col.dataFieldName
          );
          this.updateGridData(
            this.accountsData.data,
            this.accountsData.totalRecords
          );
        } else {
          this.currentTab = 'referral';
          this.displayColumnsDefined = searchResultsDefined;
          this.displayedColumns = searchResultsDefined.map(
            (col) => col.dataFieldName
          );
          this.updateGridData(
            this.referralsData.data,
            this.referralsData.totalRecords
          );
        }
      }
    }
  };

  updateGridData = (gridData: any, totalRecords: number) => {
    if (this.currentTab == 'moalist') {
      this.searchResultSelectedTabCount = this.activeTabs[0].count;
    } else if (this.currentTab == 'prospect') {
      this.searchResultSelectedTabCount = this.activeTabs[1].count;
    } else if (this.currentTab == 'lead') {
      this.searchResultSelectedTabCount = this.activeTabs[2].count;
    } else if (this.currentTab == 'opportunity') {
      this.searchResultSelectedTabCount = this.activeTabs[3].count;
    } else if (this.currentTab == 'account') {
      this.searchResultSelectedTabCount = this.activeTabs[5].count;
    } else {
      this.searchResultSelectedTabCount = this.activeTabs[4].count;
    }
    this.dataSource.data = gridData;
    this.highlightSearchText();
    this.totalRecords = totalRecords;
  };

  highlightSearchText = () => {
    this.dataSource.data.forEach((data: any) => {
      this.displayedColumns.forEach((columnName: string) => {
        if (columnName == 'fullName' && (data.firstName || data.lastName)) {
          data.fullName = data.firstName + ' ' + data.lastName;
        }
        let pos = String(data[columnName])
          .toLowerCase()
          .indexOf(this.searchTxt.toLowerCase());
        if (
          columnName != 'LEAD_CREATED_DATE' &&
          columnName != 'lastContactedDate' &&
          columnName != 'createdOn' &&
          columnName != 'avgProcessingVolume' &&
          columnName != 'lastStatusChange' &&
          columnName != 'admins' &&
          columnName != 'contributors' &&
          columnName != 'closeDate' &&
          columnName != 'phone' &&
          columnName != 'entityTwilio' &&
          pos != -1
        ) {
          let startPos = 0;
          let value = [];
          while (pos != -1) {
            value.push(data[columnName].substring(startPos, pos));
            let highlightString = data[columnName].substr(
              pos,
              this.searchTxt.length
            );
            highlightString = highlightString.split('<').join('&lt;');
            highlightString = highlightString.split('>').join('&gt;');
            value.push(`<strong>${highlightString}</strong>`);
            startPos = pos + this.searchTxt.length;
            pos = String(data[columnName])
              .toLowerCase()
              .indexOf(this.searchTxt.toLowerCase(), startPos);
            if (pos == -1) {
              value.push(
                data[columnName].substring(startPos, data[columnName].length)
              );
            }
          }
          data[columnName + '_value'] = value;
        } else {
          if (columnName == 'phone' || columnName == 'entityTwilio') {
            data[columnName + '_value'] = [formatPhoneNumber(data[columnName])];
          } else {
            data[columnName + '_value'] = [data[columnName]];
          }
        }
      });
    });
  };

  onPaginate(event: PageEvent): void {
    this.dataSource.data = [];
    let previousItemPerPage = this.pageSize;
    if (
      sessionStorage.getItem('previousIndex') &&
      Number(sessionStorage.getItem('previousIndex')) === event.pageSize
    ) {
      previousItemPerPage = Number(sessionStorage.getItem('previousIndex'));
      this.pageSize = event.pageSize;
    } else {
      sessionStorage.setItem('previousIndex', event.pageSize.toString());
    }
    if (previousItemPerPage !== event.pageSize) {
      this.pageSize = event.pageSize;
      this.paginator.firstPage();
    }
    if (this.currentTab == 'referral') {
      this.paginator.pageSize = this.pageSize;
      this.payload.page = event.pageIndex;
      this.payload.recordsPerPage = event.pageSize;

      this.headerService.setSearchPayloadPage(this.payload.page);
      this.headerService.setSearchPayloadRecordsPerPage(
        this.payload.recordsPerPage
      );
      this.invokeReferralSearch(false);
    } else {
      this.paginator.pageSize = this.pageSize;
      this.filterSearch();
    }
    this.cdRef.detectChanges();
  }

  clearFilters(columnKey: string): void {
    this.filterMap.delete(columnKey);
    this.filterSearch();
  }

  outsideClick(value: boolean): void {
    if (value) {
      this.hideFilters('');
    }
  }

  toggleFilter = (disCol: SearchResultColumns, event: MouseEvent): void => {
    disCol.showFilter = !disCol.showFilter;
    this.hideFilters(disCol.dataFieldName);
    event.stopPropagation();
  };

  hideFilters = (dataFieldName: string) => {
    this.displayColumnsDefined.forEach((column: SearchResultColumns) => {
      if (dataFieldName === '' || dataFieldName !== column.dataFieldName) {
        column.showFilter = false;
      }
    });
  };

  onApply(event: ColumnFilterValue, fieldName?: string): void {
    if (event.field === 'date') {
      const data = event.data.split(',');
      this.filterMap.set(
        fieldName,
        `${moment(data[0].split(':')[1], 'YYYYMMDD').format(
          'YYYY-MM-DD'
        )}:${moment(data[1].split(':')[1], 'YYYYMMDD').format('YYYY-MM-DD')}`
      );
    } else {
      const data = event.data.split(':');
      this.filterMap.set(data[0], data[1].trim());
    }
    this.paginator.firstPage();
    this.filterSearch();
  }

  filterSearch(): void {
    const filterVal: Map<string, string> = new Map();
    this.filterMap.forEach((val, key) => filterVal.set(key, val));
    this.hideFilters('');
    this.applySelectedFilters(filterVal);
  }

  getPayloadObject = (
    direction: string,
    fieldName: string,
    condition: string,
    value: string
  ) => {
    return {
      direction,
      fieldName,
      condition,
      value,
      options: [],
    };
  };

  generatePayloadForLeadType = () => {
    const payload: any = {
      page: this.paginator ? this.paginator.pageIndex : 0,
      recordsPerPage: this.pageSize,
      sortBy: 'dateCreated',
      sortOrder: 'DESC',
    };
    return payload;
  };

  fetchDataUsingRefineSearchData = () => {
    const predefinedTags = this.getPreDefinedTags();
    const filtersExpressions: Expression[] = [];
    predefinedTags.forEach((tags: RefineSearchTagClickedItem) => {
      filtersExpressions.push({
        direction: 'INCLUDES',
        fieldName: tags.dataFieldName,
        condition: 'CONTAINS',
        value: this.searchTxt,
        options: [],
      });
    });
    this.fetchRefineSearchData = {
      dateFilter: [],
      predefinedTags: predefinedTags,
      payload: {
        page: 0,
        recordsPerPage: 10,
        sortBy: 'dateCreated',
        sortOrder: 'DESC',
        selfLeadOnly: true,
        filters: {
          combinator: 'OR',
          expressions: filtersExpressions,
        },
      },
    };
    this.selectedFilterColumnData = 3;
    this.searchResultTotalCount = 0;
    this.searchResultSelectedTabCount = 0;
    this.filterMap = new Map();
    this.applySelectedFilters([], true);
  };

  generatePayloadForAccount = (isRefineSearch = false) => {
    const payload: any = {
      page: this.paginator ? this.paginator.pageIndex : 0,
      recordsPerPage: this.pageSize,
      sortBy: 'dateCreated',
      sortOrder: 'DESC',
      activeAccount: false,
      selfAccount: false,
      filters: {
        combinator: 'AND',
        expressions: [],
      },
    };

    if (!this.fetchRefineSearchData) {
      const searchFields = [
        'fullName',
        'phone',
        'email',
        'accountName',
        'merchantMid',
      ];
      const statusPayload: any = {
        combinator: 'OR',
        expressions: [],
      };
      statusPayload.expressions = searchFields.map((field: string) => {
        return this.getPayloadObject(
          'INCLUDES',
          field,
          'CONTAINS',
          this.searchTxt
        );
      });
      payload.filters.expressions.push(statusPayload);
    }
    return payload;
  };

  applySelectedFilters = (filterVal: any = [], refineSearch = false) => {
    const payload = this.generatePayloadForLeadType();
    payload.sortBy = ['createdOn', 'dateCreated'].includes(this.sort?.active)
      ? 'dateCreated'
      : this.sort?.active || 'dateCreated';
    payload.sortOrder = this.sort?.direction.toUpperCase() || 'DESC';
    this.gridViewFilter = filterVal;
    if (filterVal.size || this.fetchRefineSearchData) {
      payload.filters = {
        combinator: 'AND',
        expressions: [],
      };
    }
    filterVal.forEach((value: string, key: string) => {
      if (this.currentTab === 'prospect' && key === 'status') {
        payload.filters.expressions.push(
          this.getPayloadObject(
            'INCLUDES',
            key,
            'IN',
            value.split(';').join(',')
          )
        );
      } else if (key === 'leadStatus' || key === 'leadRating') {
        const statusPayload: any = {
          combinator: 'OR',
          expressions: [],
        };
        value.split(';').forEach((statusValue) => {
          statusPayload.expressions.push(
            this.getPayloadObject('INCLUDES', key, 'EQUALTO', statusValue)
          );
        });
        payload.filters.expressions.push(statusPayload);
      } else if (
        [
          'lastContactedDate',
          'closeDate',
          'createdOn',
          'lastStatusChange',
          'dateCreated',
        ].includes(key)
      ) {
        payload.filters.expressions.push(
          this.getPayloadObject(
            'INCLUDES',
            ['createdOn', 'dateCreated'].includes(key) ? 'dateCreated' : key,
            'GREATERTHANEQUAL',
            value.split(':')[0]
          )
        );
        payload.filters.expressions.push(
          this.getPayloadObject(
            'INCLUDES',
            ['createdOn', 'dateCreated'].includes(key) ? 'dateCreated' : key,
            'LESSTHANEQUAL',
            ['createdOn', 'lastStatusChange'].includes(key)
              ? moment(value.split(':')[1]).format('YYYY-MM-DD')
              : ['lastContactedDate', 'dateCreated'].includes(key)
              ? moment(value.split(':')[1]).add(1, 'days').format('YYYY-MM-DD')
              : value.split(':')[1]
          )
        );
      } else {
        value = key === 'phone' ? value.replace(/\D/g, '') : value;
        payload.filters.expressions.push(
          this.getPayloadObject(
            'INCLUDES',
            key,
            [
              'totalProspects',
              'totalOpenProspects',
              'totalLeads',
              'totalAccounts',
              'avgProcessingVolume',
              'processingVolume',
              'projectedRevenue',
              'totalOpportunities',
              'numofLists',
            ].includes(key)
              ? 'EQUALTO'
              : 'CONTAINS',
            value
          )
        );
      }
    });
    if (this.fetchRefineSearchData) {
      payload.filters.expressions = [
        ...JSON.parse(JSON.stringify(payload.filters.expressions)),
        ...JSON.parse(JSON.stringify(this.fetchRefineSearchData.dateFilter)),
      ];
      payload.selfLeadOnly = this.fetchRefineSearchData.payload.selfLeadOnly;
      if (!this.displayReferralSearch) {
        payload.unqualified =
          this.fetchRefineSearchData.payload.unqualified || false;
      }
      payload.filters.expressions.push(
        JSON.parse(JSON.stringify(this.fetchRefineSearchData.payload.filters))
      );
      payload.activeAccount =
        this.fetchRefineSearchData.payload.unqualified || false;
    }
    delete payload.unqualified;
    if (refineSearch) {
      payload.page = 0;
      this.resetData();
      const leadPayload = { ...JSON.parse(JSON.stringify(payload)) };
      const opportunityPayload = { ...JSON.parse(JSON.stringify(payload)) };
      const accountPayload = { ...JSON.parse(JSON.stringify(payload)) };
      const moaListPayload = { ...JSON.parse(JSON.stringify(payload)) };
      const prospectPayload = { ...JSON.parse(JSON.stringify(payload)) };

      opportunityPayload.filters.expressions.unshift({
        combinator: 'OR',
        expressions: [
          {
            direction: 'EXCLUDES',
            fieldName: 'O.\"opportunityTypeId\"',
            condition: 'EQUALTO',
            value: 3,
            options: [],
          },
        ],
      });

      if (this.fetchRefineSearchData.payload.unqualified) {
        const leadObj = this.getPayloadObject(
          'EXCLUDES',
          'leadStatus',
          'EQUALTO',
          'Unqualified'
        );
        const opportunityObj = this.getPayloadObject(
          'EXCLUDES',
          'salesStage',
          'EQUALTO',
          'CLOSE LOST'
        );
        leadPayload.filters.expressions.unshift(leadObj);
        opportunityPayload.filters.expressions.unshift(opportunityObj);
      }

      if (this.fetchRefineSearchData.payload.doNotContact) {
        const prospectObj = this.getPayloadObject(
          'EXCLUDES',
          'status',
          'EQUALTO',
          'Do Not Contact'
        );
        prospectPayload.filters.expressions.unshift(prospectObj);
      }

      opportunityPayload.filters.expressions.forEach((ele: any) => {
        if (ele.combinator) {
          ele.expressions = ele.expressions.filter(
            (element: any) => element.fieldName != 'merchantMid'
          );
          ele.expressions.forEach((element: any) => {
            if (
              ['company', 'businessName', 'accountName'].includes(
                element.fieldName
              )
            ) {
              element.fieldName = 'legalName';
            }
          });
        }
      });
      leadPayload.filters.expressions.forEach((ele: any) => {
        if (ele.combinator) {
          ele.expressions = ele.expressions.filter(
            (element: any) => element.fieldName != 'merchantMid'
          );
          ele.expressions.forEach((element: any) => {
            if (
              ['legalName', 'businessName', 'accountName'].includes(
                element.fieldName
              )
            ) {
              element.fieldName = 'company';
            }
          });
        }
      });
      accountPayload.filters.expressions.forEach((ele: any) => {
        if (ele.combinator) {
          ele.expressions = ele.expressions.filter(
            (element: any) => element.fieldName != 'entityTwilio'
          );
          ele.expressions.forEach((element: any) => {
            if (
              ['company', 'legalName', 'businessName'].includes(
                element.fieldName
              )
            ) {
              element.fieldName = 'accountName';
            }
          });
        }
      });

      if (this.paginator) {
        this.paginator.firstPage();
      }
      if (!this.displayReferralSearch) {
        opportunityPayload.selfOpportunityOnly =
          opportunityPayload.selfLeadOnly || false;
        delete opportunityPayload.selfLeadOnly;
        accountPayload.selfAccount = accountPayload.selfLeadOnly || false;
        delete leadPayload.activeAccount;
        delete opportunityPayload.activeAccount;
        delete accountPayload.selfLeadOnly;
        delete accountPayload.searchValue;
      } else {
        delete payload.activeAccount;
        delete payload.selfLeadOnly;
        delete payload.unqualified;
      }
      delete prospectPayload.doNotContact;
      if (this.displayReferralSearch) {
        this.payload = { ...this.headerService.getSearchPayload() };
        this.invokeReferralSearch(true);
      } else {
        this.invokeSearch(leadPayload, true, 'lead');
        this.invokeSearch(opportunityPayload, true, 'opportunity');
        if (!this.isSdUser) {
          this.invokeSearch(accountPayload, true, 'account');
        }
      }
      if (this.hasMoaPriviledge) {
        moaListPayload.filters.expressions.pop();
        if (moaListPayload.selfLeadOnly && this.isMoaAdmin) {
          moaListPayload.filters.expressions.push(
            this.getPayloadObject(
              'INCLUDES',
              'prospectSourceUserId',
              'EQUALTO',
              this.userInfoService.getUserId()
            )
          );
        }
        delete moaListPayload.doNotContact;
        delete moaListPayload.selfLeadOnly;
        delete moaListPayload.activeAccount;
        this.invokeSearch(moaListPayload, true, 'moalist', true);
        if (this.isMoaAdmin) {
          prospectPayload.filters.expressions.forEach((ele: any) => {
            if (ele.combinator) {
              ele.expressions.forEach((element: any) => {
                if (
                  ['company', 'legalName', 'accountName'].includes(
                    element.fieldName
                  )
                ) {
                  element.fieldName = 'businessName';
                }
              });
            }
          });
          prospectPayload.filters.expressions[
            prospectPayload.filters.expressions.length - 1
          ].expressions = prospectPayload.filters.expressions[
            prospectPayload.filters.expressions.length - 1
          ].expressions.filter((item: any) =>
            ['businessName', 'email', 'phone'].includes(item.fieldName)
          );
          if (prospectPayload.selfLeadOnly) {
            prospectPayload.filters.expressions.push(
              this.getPayloadObject(
                'INCLUDES',
                'prospectSourceUserId',
                'EQUALTO',
                this.userInfoService.getUserId()
              )
            );
          }
          delete prospectPayload.doNotContact;
          delete prospectPayload.selfLeadOnly;
          delete prospectPayload.activeAccount;
          this.invokeSearch(prospectPayload, true, 'prospect', true);
        }
      }
    } else {
      const payloadData = JSON.parse(JSON.stringify(payload));
      if (this.currentTab === 'opportunity') {
        delete payloadData.activeAccount;
        if (
          this.fetchRefineSearchData &&
          this.fetchRefineSearchData.payload.unqualified
        ) {
          const opportunityObj = this.getPayloadObject(
            'EXCLUDES',
            'salesStage',
            'EQUALTO',
            'CLOSE LOST'
          );
          payloadData.filters.expressions.unshift(opportunityObj);
        }
        if (payloadData.filters && payloadData.filters.expressions) {
          payloadData.filters.expressions.forEach((ele: any) => {
            if (ele.combinator) {
              ele.expressions = ele.expressions.filter(
                (element: any) => element.fieldName != 'merchantMid'
              );
              ele.expressions.forEach((element: any) => {
                if (
                  ['company', 'businessName', 'accountName'].includes(
                    element.fieldName
                  )
                ) {
                  element.fieldName = 'legalName';
                }
              });
            }
          });
        }
        payloadData.filters.expressions.unshift({
          combinator: 'OR',
          expressions: [
            {
              direction: 'EXCLUDES',
              fieldName: 'O.\"opportunityTypeId\"',
              condition: 'EQUALTO',
              value: 3,
              options: [],
            },
          ],
        });
        payloadData.selfOpportunityOnly = payloadData.selfLeadOnly;
        delete payloadData.selfLeadOnly;
      } else if (this.currentTab === 'lead') {
        delete payloadData.activeAccount;
        if (payloadData.filters && payloadData.filters.expressions) {
          payloadData.filters.expressions.forEach((ele: any) => {
            if (ele.combinator) {
              ele.expressions = ele.expressions.filter(
                (element: any) => element.fieldName != 'merchantMid'
              );
              ele.expressions.forEach((element: any) => {
                if (
                  ['legalName', 'businessName', 'accountName'].includes(
                    element.fieldName
                  )
                ) {
                  element.fieldName = 'company';
                }
              });
            }
          });
        }
        if (
          this.fetchRefineSearchData &&
          this.fetchRefineSearchData.payload.unqualified
        ) {
          const leadObj = this.getPayloadObject(
            'EXCLUDES',
            'leadStatus',
            'EQUALTO',
            'Unqualified'
          );
          payloadData.filters.expressions.unshift(leadObj);
        }
      } else if (this.currentTab === 'account') {
        payloadData.selfAccount = payloadData.selfLeadOnly;
        delete payloadData.selfLeadOnly;
        if (payloadData.filters && payloadData.filters.expressions) {
          payloadData.filters.expressions.forEach((ele: any) => {
            if (ele.combinator) {
              ele.expressions = ele.expressions.filter(
                (element: any) => element.fieldName != 'entityTwilio'
              );
              ele.expressions.forEach((element: any) => {
                if (
                  ['company', 'legalName', 'businessName'].includes(
                    element.fieldName
                  )
                ) {
                  element.fieldName = 'accountName';
                }
              });
            }
          });
        }
      } else {
        delete payloadData.activeAccount;
        if (this.fetchRefineSearchData && this.currentTab === 'prospect') {
          payloadData.filters.expressions.forEach((ele: any) => {
            if (ele.combinator) {
              ele.expressions.forEach((element: any) => {
                if (
                  ['legalName', 'company', 'accountName'].includes(
                    element.fieldName
                  )
                ) {
                  element.fieldName = 'businessName';
                }
              });
            }
          });
          payloadData.filters.expressions[
            payloadData.filters.expressions.length - 1
          ].expressions = payloadData.filters.expressions[
            payloadData.filters.expressions.length - 1
          ].expressions.filter((item: any) =>
            ['businessName', 'email', 'phone'].includes(item.fieldName)
          );
          if (this.fetchRefineSearchData.payload.doNotContact) {
            const prospectObj = this.getPayloadObject(
              'EXCLUDES',
              'status',
              'EQUALTO',
              'Do Not Contact'
            );
            payloadData.filters.expressions.unshift(prospectObj);
          }
        }
        if (this.fetchRefineSearchData && this.currentTab === 'moalist') {
          payloadData.filters.expressions.pop();
          if (payloadData.selfLeadOnly && this.isMoaAdmin) {
            payloadData.filters.expressions.push(
              this.getPayloadObject(
                'INCLUDES',
                'prospectSourceUserId',
                'EQUALTO',
                this.userInfoService.getUserId()
              )
            );
          }
        }
        delete payloadData.selfLeadOnly;
      }
      this.invokeSearch(payloadData, false, this.currentTab);
    }
  };
  navigateToDetails = (referralData: any) => {
    if (referralData.LEAD_SF15_ID) {
      this.referralService
        .getReferralUserDetails(referralData.LEAD_SF15_ID)
        .subscribe(
          (data: any) => {
            if (data.statusCode === 200) {
              if (data.data.entityType === 'LEAD') {
                this.router.navigate([`/leads/details/${data.data.entityId}`]);
              } else if (data.data.entityType === 'OPPORTUNITY') {
                this.router.navigate([
                  `/opportunities/details/${data.data.entityId}`,
                ]);
              }
            } else {
              this.toasterService.error(data?.message);
            }
          },
          (error) => {
            this.toasterService.error(error);
          }
        );
    }
  };

  getTooltipData(tooltipData: string[]) {
    return tooltipData.map((data: string) => data).join('\n');
  }

  ngOnDestroy(): void {
    if (this.destroy$) {
      this.destroy$.unsubscribe();
    }
    if (this.globalSearchSubscription) {
      this.globalSearchSubscription.unsubscribe();
    }
    if (this.subscriptionGetSearchResults) {
      this.subscriptionGetSearchResults.unsubscribe();
    }
  }
}
