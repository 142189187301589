export const bankId = 2;
export const pageSizeOptions = [10, 50, 100];
export const prospectSizeOptions = [100, 250, 500, 1000];
export const listSizeOptions = [10, 100, 250, 500, 1000];
export const EMAIL_PATTERN =
  '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,4}$';
export const PHONE_PATTERN = /^\(\d{3}\)\s\d{3}-\d{4}$/;
export const ZIP_PATTERN = '[0-9]{1,5}$';
export const ALPHABET = '^[a-zA-Z- ]+$';
export const caZipPattern = '[A-Za-z][0-9][A-Za-z] ?[0-9][a-zA-Z][0-9]';
export const usZipPattern = '[0-9]{5}|[0-9]{5}?[- ]?[0-9]{4}';
export const midPattern = '[0-9]{12,12}';
export const comboZipPattern =
  '[0-9]{5}|[0-9]{5}?[- ]?[0-9]{4}|^[A-Za-z][0-9][A-Za-z] ?[0-9][a-zA-Z][0-9]';
export const periodList: string[] = ['AM', 'PM'];
export const marketoFileType = [
  '.jpg',
  '.jpeg',
  '.png',
  '.pdf',
  '.csv',
  '.xlsx',
];
export const emailAttachmentFileType = [
  '.jpg',
  '.jpeg',
  '.png',
  '.pdf',
  '.doc',
  '.docx',
];
export const attachmemtsMaxSize = 1024 * 1024 * 3; // 3MB
export const profileImageMaxSize = 1024 * 500; // 500KB
export const productionUrl = [
  'https://int.catalyst.clover.com',
  'https://catalyst.clover.com',
];
export const websocketURL = {
  prod: 'wss://prod-websocket.prod.catalyst.clover.com',
  dev: 'wss://0y8r2g8gke.execute-api.us-west-2.amazonaws.com/dev',
};
export const NonMoAENabled = {
  privilegeName: 'NON_MOA_ENABLED',
  privilegeDisplayName: 'Not MOA Enabled',
  id: 0,
};
export const leadConvert = [
  'firstName',
  'lastName',
  'phone',
  'email',
  'company',
  'mccCategoryID',
  'mccDescriptionId',
];
export const leadConvertLabel = {
  firstName: 'First Name',
  lastName: 'Last Name',
  phone: 'Phone',
  email: 'Email',
  legalBusinessName: 'Legal Business Name',
  businessPhone: 'Business Phone',
  address: 'Address',
  zip: 'Zip',
  mccCategoryID: 'Industry Type',
  mccDescriptionId: 'Sub-Industry',
};
export const defaultMenus = [
  {
    url: '/overview',
    image: 'overview',
    label: 'shared.common.overview',
    name: 'overview',
  },
  {
    url: '/dashboard',
    image: 'overview',
    label: 'leftNav.dashboard',
    name: 'dashboard',
  },
  {
    url: '/activityfeed',
    image: 'activityfeed',
    label: 'activity.feed',
    name: 'activityfeed',
  },
  {
    url: '/agenda',
    image: 'agenda',
    label: 'leftNav.agenda',
    name: 'agenda',
    showCount: true,
    count: 0,
  },
  {
    url: '/moa',
    image: 'prospects',
    label: 'leftNav.prospects',
    name: 'prospect',
  },
  {
    url: '/leads',
    image: 'leads',
    label: 'shared.leads',
    name: 'leads',
  },
  {
    url: '/opportunities',
    image: 'opportunities',
    label: 'shared.opportunities',
    name: 'opportunities',
  },
  {
    url: '/myaccounts',
    image: 'accounts',
    label: 'shared.accounts',
    name: 'myaccounts',
  },
  {
    url: '/marketing',
    image: 'marketing',
    label: 'leftNav.marketing',
    name: 'marketing',
  },
  {
    url: '/audience',
    image: 'comp_analysis',
    label: 'shared.audience',
    name: 'audience',
  },
  {
    url: '/referral/my-referral',
    image: 'my_referrals',
    label: 'shared.myreferral',
    name: 'myreferrals',
  },
  {
    url: '/referral/referral-activity',
    image: 'my_referrals',
    label: 'shared.referralActivity',
    name: 'referralactivity',
  },
  {
    url: '/campaign',
    image: 'marketing',
    label: 'shared.campaignPlanner',
    name: 'campaign planner',
  },
  {
    url: '/sequence',
    image: 'sequence',
    label: 'shared.sequence.builder',
    name: 'sequencebuilder',
  },
  {
    url: '/messages',
    image: 'message',
    label: 'leftNav.messages',
    showCount: true,
    name: 'messages',
  },
  {
    url: '/academy',
    image: 'academy',
    label: 'shared.pageHeading',
    name: 'cloveracademy',
  },
  // {
  //   url: '/myteam',
  //   image: 'myteam',
  //   label: 'shared.myTeam',
  //   name: 'myteam',
  // },
  {
    url: '/sales',
    image: 'sales_routing',
    label: 'shared.sales.routing',
    name: 'salesrouting',
  },
  {
    url: '/settings',
    image: 'settings',
    label: 'leftNav.settings',
    name: 'settings',
  },
];
export const editableConfig = {
  editable: true,
  spellcheck: true,
  enableToolbar: false,
  showToolbar: false,
};

export const replaceableEntityNumber = ['&#160;'];
export const replacedEntityNumber = [' '];
export const appStaticContent = {
  expirePopupButton: 'session.expire.button',
  mobilePhoneConsentMessage: 'mobileConsent',
  mobilePhoneButtonConsent: 'mobileConsentButton',
  mobilePhoneButtonRevoke: 'profile.msalAccessTokenPresent.revoke',
  somethingApiError: 'something.wrong',
  expirePopupTitle: 'session.expire.heading',
  expirePopupBody: 'session.expire.message',
  preferenceFetchError: 'preference.fetch.error',
  cancel: 'common.button.text.cancel',
  mobilePhoneConsentHeading: 'mobileConsentHeading',
};

export const mobileMenuList = [
  {
    url: '/referral/my-referral',
    image: 'my_referrals',
    label: 'My Referrals',
    name: 'myreferrals',
  },
  {
    url: '/leads',
    image: 'leads',
    label: 'Leads',
    name: 'leads',
  },
  {
    url: '/settings',
    image: 'settings',
    label: 'Settings',
    name: 'settings',
  },
];
