import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DigitalActivityContent,
  DigitalActivityList,
  DigitalHeapActivityResponse,
  ActivityLogPayload,
} from '@app/model/interfaces/leads';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogActivityService {
  refreshTextList = new BehaviorSubject('');
  refreshNotesList = new BehaviorSubject('');
  refreshCallList = new BehaviorSubject({ moduleName: '', taskName: '' });
  constructor(private http: HttpClient) {}

  getServiceUrl = (moduleName: string) => {
    switch (moduleName) {
      case 'account':
        return environment.activityServices;
      case 'prospect':
        return environment.prospectServices;
      default:
        return environment.salesRegimeServices;
    }
  };

  createActivity(
    moduleName: string,
    id: number,
    payload: ActivityLogPayload
  ): Observable<ActivityLogPayload> {
    const serviceUrl = this.getServiceUrl(moduleName);
    return this.http
      .post(`${serviceUrl}/v1/${moduleName}/${id}/activity`, payload)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getActivitiesAllTab(
    moduleName: string,
    id: number
  ): Observable<string | number> {
    const serviceUrl = this.getServiceUrl(moduleName);
    return this.http
      .get<string | number>(`${serviceUrl}/v1/${moduleName}/${id}/activities`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getActivitiesTab(
    moduleName: string,
    id: number
  ): Observable<string | number> {
    const serviceUrl = this.getServiceUrl(moduleName);
    return this.http
      .get<string | number>(`${serviceUrl}/v1/${moduleName}/${id}/activity`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  getActivitiesTabList(
    moduleName: string,
    activityType: string,
    id: number
  ): Observable<string | number> {
    const serviceUrl = this.getServiceUrl(moduleName);
    return this.http
      .get<string | number>(
        `${serviceUrl}/v1/${moduleName}/${id}/activity/${activityType}`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }
  postingText = (
    moduleName: string,
    id: number,
    message: string
  ): Observable<any> => {
    const urlDomain =
      moduleName === 'prospect' ? 'prospectServices' : 'salesRegimeServices';
    const payload = { message };
    const url = `${environment[urlDomain]}/v1/${moduleName}/${id}/messages/texts`;
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap(() => {})
    );
  };

  getProspectDigitalActivity = (
    moduleName: string,
    id: number
  ): Observable<any> => {
    const serviceUrl = this.getServiceUrl(moduleName);
    return this.http
      .get<any>(`${serviceUrl}/v1/${moduleName}/${id}/activity/digital`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  };
  getDigitalActivity(
    moduleName: string,
    id: number
  ): Observable<DigitalActivityList> {
    const serviceUrl = this.getServiceUrl(moduleName);
    return this.http
      .get<DigitalActivityList>(`${serviceUrl}/v1/${moduleName}/${id}/marketo`)
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getDigitalActivityContent(moduleName: string, id: number): Observable<any> {
    const urlDomain =
      moduleName === 'prospect' ? 'prospectServices' : 'salesRegimeServices';
    return this.http
      .get<DigitalActivityContent>(
        `${environment[urlDomain]}/v1/marketo/content/${id}`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  getDigitalActivityHeap(
    moduleName: string,
    id: number
  ): Observable<DigitalHeapActivityResponse> {
    const serviceUrl = this.getServiceUrl(moduleName);
    return this.http
      .get<DigitalHeapActivityResponse>(
        `${serviceUrl}/v1/${moduleName}/${id}/heap`
      )
      .pipe(
        catchError(this.handleError),
        tap(() => {})
      );
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
