import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FaqComponent } from './faq/faq.component';
import { MaterialModule } from './module/material/material.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { from, Observable } from 'rxjs';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { SharedUploadComponent } from './component/file/shared-upload/shared-upload.component';
import { CloverDualListDragdropComponent } from '@app/features/referral/shared-components/clover-dual-list-dragdrop/clover-dual-list-dragdrop.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ViewQuickTextsComponent } from './view-quick-texts/view-quick-texts.component';
import { NoDataComponent } from './no-data/no-data.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DirectiveModule } from './directive/directive.module';
import { ProspectingDetailsComponent } from './component/prospecting-details/prospecting-details.component';

import { LogActivityComponent } from './component/log-activity/log-activity/log-activity.component';
import { TextActivityComponent } from './component/log-activity/text-activity/text-activity.component';
import { FileManagementComponent } from './component/file/file-management/file-management.component';
import { ReminderManagementComponent } from './component/reminder/reminder-management/reminder-management.component';
import { DigitalActivityComponent } from './component/log-activity/digital-activity/digital-activity.component';
import { UnQualifyLeadDialogComponent } from '@app/features/leads/un-qualify-lead-dialog/un-qualify-lead-dialog.component';
import { SafehtmlPipe } from './safehtml.pipe';
import { MicrosoftAuthComponent } from './component/microsoft-auth/microsoft-auth.component';
import { InviteMailComponent } from './send-mail/send-mail.component';
import { OpportunitiesStatusErrorComponent } from '@app/features/opportunities/opportunities-status-error/opportunities-status-error.component';
import { TaskUpdateMessageComponent } from './task-update-message/task-update-message.component';
import { TextComponent } from './text/text.component';
import { CallLogDialogComponent } from './call/call-log-dialog/call-log-dialog.component';
import { ScheduleCallbackDialogComponent } from './call/schedule-callback-dialog/schedule-callback-dialog.component';
import { MatColumnFilterComponent } from './component/form/mat-column-filter/mat-column-filter.component';
import { AngularModule } from './angular/angular.module';
import { LeadSequenceComponent } from '@app/features/leads/lead-sequence/lead-sequence.component';
import { ActiveSequenceDialogComponent } from '@app/features/leads/active-sequence-dialog/active-sequence-dialog.component';
import { EndSequenceComponent } from './end-sequence/end-sequence.component';
import { SearchBcDialogComponent } from './call/search-bc-dialog/search-bc-dialog.component';
import { TransferCallDialogComponent } from './call/transfer-call-dialog/transfer-call-dialog.component';
import { FooterOptionsDialogComponent } from './component/mobile/footer-options-dialog/footer-options-dialog.component';
import { ReminderFormComponent } from './component/mobile/reminder-form/reminder-form.component';
import { AddActivityDialogComponent } from './component/log-activity/add-activity-dialog/add-activity-dialog.component';
import { CurrentLocationComponent } from './component/map/current-location/current-location.component';
import { AddListAdminComponent } from './add-list-admin/add-list-admin.component';
import { ViewGridComponent } from './component/view-grid/view-grid.component';
import { OpportunitiesComponent } from '@app/features/opportunities/opportunities/opportunities.component';
import { CommentsComponent } from './comments/comments.component';
export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<string> {
    return from(import(`../../assets/i18n/${lang}.json`));
  }
}
@NgModule({
  declarations: [
    FaqComponent,
    SharedUploadComponent,
    CloverDualListDragdropComponent,
    ViewQuickTextsComponent,
    NoDataComponent,
    ConfirmComponent,
    ProspectingDetailsComponent,
    LogActivityComponent,
    TextActivityComponent,
    FileManagementComponent,
    ReminderManagementComponent,
    DigitalActivityComponent,
    UnQualifyLeadDialogComponent,
    SafehtmlPipe,
    MicrosoftAuthComponent,
    InviteMailComponent,
    OpportunitiesStatusErrorComponent,
    TaskUpdateMessageComponent,
    TextComponent,
    CallLogDialogComponent,
    ScheduleCallbackDialogComponent,
    MatColumnFilterComponent,
    LeadSequenceComponent,
    ActiveSequenceDialogComponent,
    EndSequenceComponent,
    SearchBcDialogComponent,
    TransferCallDialogComponent,
    FooterOptionsDialogComponent,
    ReminderFormComponent,
    AddActivityDialogComponent,
    CurrentLocationComponent,
    AddListAdminComponent,
    ViewGridComponent,
    OpportunitiesComponent,
    CommentsComponent,
  ],
  imports: [
    CommonModule,
    DirectiveModule,
    AngularModule,
    MaterialModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AngularEditorModule,
    TranslateModule.forRoot({
      isolate: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
        useClass: LazyTranslateLoader,
      },
    }),
  ],
  providers: [CurrencyPipe],
  exports: [
    MatColumnFilterComponent,
    AngularModule,
    DirectiveModule,
    FaqComponent,
    MaterialModule,
    SharedUploadComponent,
    HttpClientModule,
    HttpClientJsonpModule,
    CloverDualListDragdropComponent,
    UnQualifyLeadDialogComponent,
    AngularEditorModule,
    ViewQuickTextsComponent,
    GoogleMapsModule,
    NoDataComponent,
    ConfirmComponent,
    ProspectingDetailsComponent,
    LogActivityComponent,
    TextActivityComponent,
    FileManagementComponent,
    ReminderManagementComponent,
    DigitalActivityComponent,
    CurrencyPipe,
    SafehtmlPipe,
    MicrosoftAuthComponent,
    InviteMailComponent,
    OpportunitiesStatusErrorComponent,
    TaskUpdateMessageComponent,
    TextComponent,
    CallLogDialogComponent,
    ScheduleCallbackDialogComponent,
    TranslateModule,
    LeadSequenceComponent,
    ActiveSequenceDialogComponent,
    EndSequenceComponent,
    ReminderFormComponent,
    ViewGridComponent,
    OpportunitiesComponent
  ],
})
export class SharedModule {}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
