import { Component, Inject, Input, OnInit } from '@angular/core';
import { MessageService } from '@app/features/message/message.service';
import { Subscription } from 'rxjs';
import { HeaderService } from '../services/header.service';
import { LogActivityService } from '../services/log-activity.service';
import { ToasterService } from '../services/toaster.service';
import { SharedService } from '../shared.service';
import {
  getEncodingURL,
  pickOneDate,
  sortactivtiesbyDate,
  updateTimeDifference,
} from '../utils/functions';
import { AdhocMessageService } from '../services/adhoc-message.service';
import * as moment from 'moment';
import { messageInfo } from '@app/model/interfaces/text';
import { MatDialog } from '@angular/material/dialog';
import { ViewQuickTextsComponent } from '../view-quick-texts/view-quick-texts.component';
import { UserInfoService } from '../services/user-info.service';
import { SettingsService } from '@app/features/settings/settings.service';
import { UserDetailsResponse } from '@app/model/interfaces/settings';
@Component({
  selector: 'clover-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {
  @Input() data: any;
  @Input() index!: number;
  chatWindowWidth = 370;
  spacingBtnChatWindow = 20;
  isReferralUser = this.sharedService.isReferralUser();
  userDetails!: any;
  moduleName!: string;
  userType!: string;
  recipientId!: string;
  recipientImgUrl!: string;
  senderId = this.userInfoService.getUserId();
  senderImgUrl!: string;
  isSmartDevice = !this.userInfo.getUserDevice();
  textActivityTab: messageInfo[] = [];
  textFromRTE: any = [];
  textSubscription!: Subscription;
  chatLoaded = false;
  minimized = false;
  maximized = false;
  chat = '';
  twilioMappingId = 0;
  entityId = 0;
  activityId = 0;
  from: string = '';
  constructor(
    private logActivityService: LogActivityService,
    private messageService: MessageService,
    private toasterService: ToasterService,
    private headerService: HeaderService,
    private sharedService: SharedService,
    private settingsService: SettingsService,
    private userInfoService: UserInfoService,
    private adhocMessageService: AdhocMessageService,
    public dialog: MatDialog,
    private userInfo: UserInfoService
  ) {}

  ngOnInit(): void {
    this.from = this.data.from;
    if (this.from === 'message-module') {
      this.twilioMappingId = this.data.user.twilioMappingId;
      this.activityId = this.data.user.activityId;
    }
    this.entityId = this.data.user.entityId;
    this.userDetails = { ...this.data.user };
    this.userType = this.data.userType;
    this.moduleName = this.data.moduleName;
    if (this.userDetails.recipientId) {
      this.recipientId = this.userDetails.recipientId;
      let usersProfile = this.settingsService.getUsersProfile();
      if (usersProfile && usersProfile['t_' + this.recipientId]) {
        if (usersProfile['t_' + this.recipientId].imageUrl) {
          this.recipientImgUrl = usersProfile['t_' + this.recipientId].imageUrl;
        }
      } else {
        this.settingsService
          .getUserDetail(this.recipientId)
          .subscribe((data: UserDetailsResponse) => {
            this.settingsService.setUsersProfile(
              't_' + this.recipientId,
              data.userDetails
            );
            this.recipientImgUrl =
              data.userDetails && data.userDetails.imageUrl;
          });
      }
    }
    if (this.senderId) {
      let usersProfile = this.settingsService.getUsersProfile();
      if (usersProfile && usersProfile['t_' + this.senderId]) {
        if (usersProfile['t_' + this.senderId].imageUrl) {
          this.senderImgUrl = usersProfile['t_' + this.senderId].imageUrl;
        }
      } else {
        this.settingsService
          .getUserDetail(this.senderId)
          .subscribe((data: UserDetailsResponse) => {
            this.settingsService.setUsersProfile(
              't_' + this.senderId,
              data.userDetails
            );
            this.senderImgUrl = data.userDetails && data.userDetails.imageUrl;
          });
      }
    }

    this.getBodyContent();
    this.textSubscription = this.headerService
      .onMessageReceived()
      .subscribe((res: any) => {
        if (this.entityId === res.entityId) {
          const rteMessage: messageInfo = {
            createdAt: moment(new Date()).format(),
            id: res.entityId,
            inBound: true,
            message: res.messageContent.split('\n').join('<br/>'),
            status: 'SENT',
            userFirstName: res.firstName,
            userLastName: res.lastName,
          };

          if (this.chatLoaded) {
            this.textActivityTab = [rteMessage, ...this.textActivityTab];
            this.showTime(this.textActivityTab);
          } else {
            this.textFromRTE = [rteMessage, ...this.textFromRTE];
          }
        }
      });
  }

  getBodyContent = () => {
    this.minimized = false;
    this.maximized = false;
    this.messageService
      .getMessages(
        this.moduleName,
        this.entityId,
        this.userType.toLowerCase(),
        this.twilioMappingId,
        this.activityId
      )
      .subscribe(
        (res: any) => {
          this.chatLoaded = true;
          if (res.statusCode === 200) {
            res.data.currentActivity = res.data.currentActivity || [];
            this.textActivityTab = [
              ...this.textFromRTE,
              ...res.data.currentActivity,
            ];
            this.showTime(this.textActivityTab);
          } else {
            this.toasterService.error(res.message);
          }
        },
        (err: any) => {
          this.chatLoaded = true;
          this.toasterService.error(err);
        }
      );
  };

  showTime = (currentActivity: messageInfo[]) => {
    currentActivity.forEach((message: messageInfo, index: number) => {
      message.showTime = true;
      message.showIcon = true;
      message.message = message.message.split('\n').join('<br/>');
      message.time = this.getDate(message);
      if (index) {
        const compareDate = currentActivity[index - 1].createdAt;
        if (
          message.inBound === currentActivity[index - 1].inBound &&
          moment(message.createdAt).format('MMM D, y, h:mm a') ===
            moment(compareDate).format('MMM D, y, h:mm a')
        ) {
          message.showTime = false;
          currentActivity[index - 1].showIcon = false;
        }
      }
    });
  };

  onSubmit = () => {
    if (this.chat.trim() == '') {
      return;
    }
    let usersProfile = this.settingsService.getUsersProfile();
    const payload: messageInfo = {
      createdAt: moment(new Date()).format(),
      createdTime: new Date().getTime(),
      inBound: false,
      message: this.chat,
      userId: usersProfile.userId,
      imageUrl: usersProfile.imageUrl,
      status: 'sending',
    };
    this.textActivityTab = [payload, ...this.textActivityTab];
    this.showTime(this.textActivityTab);
    this.messageService
      .sendMessage(
        this.moduleName,
        this.entityId,
        this.userType.toLowerCase(),
        this.chat
      )
      .subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.textActivityTab.forEach((message: messageInfo) => {
              if (message.createdTime === payload.createdTime) {
                message.status = 'SENT';
              }
            });
            this.logActivityService.refreshTextList.next('Text');
          } else {
            this.toasterService.error(res.message);
          }
        },
        (err: any) => {
          this.toasterService.error(err);
        }
      );
    this.chat = '';
  };
  minimizeWindow = () => {
    if (this.maximized) {
      this.maximized = false;
      this.minimized = false;
    } else {
      this.minimized = true;
    }
  };

  maximizeWindow = () => {
    if (this.minimized) {
      this.maximized = false;
      this.minimized = false;
    } else {
      this.maximized = true;
    }
  };

  getDate = (tabItem: any) => updateTimeDifference(pickOneDate(tabItem));

  close = () => {
    this.adhocMessageService.updateConversationListUpdated({
      action: 'close',
      data: this.data,
    });
  };
  insertQuickText = () => {
    const dialogRef = this.dialog.open(ViewQuickTextsComponent, {
      width: '1000px',
      data: {
        convertToValue: true,
        entityType: this.data.moduleName,
        entityId: this.entityId,
      },
    });
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.chat = `${this.chat}${this.chat ? '\n' : ''}${result}`;
      }
    });
  };
  getEncodingURL = (imagePath: string | undefined) => getEncodingURL(imagePath);
  ngOnDestroy(): void {
    this.textSubscription.unsubscribe();
  }
}
